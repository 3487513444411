import { BehaviorSubject } from "rxjs";

import config from "../config";
import { handleResponse } from "../_helpers";
import EncDec from "../e2e/e2e_functions/index";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const currentDepartmentSubject = new BehaviorSubject(
  localStorage.getItem("currentDepartment")
);
const currentDepartmentNameSubject = new BehaviorSubject(
  localStorage.getItem("currentDepartmentName")
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  currentDepartment: currentDepartmentSubject.asObservable(),
  get currentDepartmentValue() {
    return currentDepartmentSubject.value;
  },
  get currentDepartmentNameValue() {
    return currentDepartmentNameSubject.value;
  },
  changeDepartment,
  setDepartmentName,
  refreshApmaProfileImage
};

function changeDepartment(departmentId, departmentName) {
  localStorage.setItem("currentDepartment", departmentId);
  currentDepartmentSubject.next(departmentId);
  setDepartmentName(departmentName);
}
function setDepartmentName(departmentName) {
  currentDepartmentNameSubject.next(departmentName);
  localStorage.setItem("currentDepartmentName", departmentName);
}
function encData(data) {
  console.log("encData::", data);
  let isenc = localStorage.getItem("isenc")
    ? parseInt(localStorage.getItem("isenc"))
    : 0;
  if (isenc == 1) {
    return JSON.stringify({
      enc_string: EncDec.encryptResponse(JSON.stringify(data))
    });
  } else {
    return JSON.stringify(data);
  }
}

function login(email, password, captcha) {
  const requestOptions = {
    method: "POST",
    //headers: { 'Content-Type': 'application/json' },
    headers: {
      "Content-Type": "application/json",
      isenc: localStorage.getItem("isenc") || 0
    },

    //body: JSON.stringify({ email, password })
    body: encData({ email, password, captcha })
  };

  return fetch(`${config.apiUrl}/api/v1/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      console.log("currentUser: ", user);
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user.Data));
      currentUserSubject.next(user.Data);
      //store department selection default1
      localStorage.setItem("currentDepartment", user.Data?.departments[0]);
      currentDepartmentSubject.next(user.Data?.departments[0]);

      return user.Data;
    });
}

function refreshApmaProfileImage(image) {
  let userData = JSON.parse(sessionStorage.getItem("currentUser"));
  userData.companyLogo = image;
  sessionStorage.setItem("currentUser", JSON.stringify(userData));
  currentUserSubject.next(userData);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  localStorage.removeItem("currentDepartment");
  currentUserSubject.next(null);
  currentDepartmentSubject.next(null);
}
