import config, { apiUrl } from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

export const sectorService = {
  addAndUpdateSector,
  getAllSectors,
  deleteSectorById,
  getSectorById
};

function addAndUpdateSector(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/sectors/addAndUpdateSector`,
    requestOptions
  ).then(handleResponse);
}

function getAllSectors() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/sectors/getAllSectors`,
    requestOptions
  ).then(handleResponse);
}

function deleteSectorById(requestData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(requestData)
  };

  return fetch(
    `${config.apiUrl}/api/v1/sectors/deleteSectorById`,
    requestOptions
  ).then(handleResponse);
}

function getSectorById(sectorId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/sectors/getSectorById/${sectorId}`,
    requestOptions
  ).then(handleResponse);
}
