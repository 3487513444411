import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { departmentService } from "../../_services/superadmin/department.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Preloader from "../../components/Preloader";
import * as Yup from "yup";
export default class AddEditDepartment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      department: {},
    };
  }

  componentDidMount() {
    this.props.match.params.id &&
      departmentService
        .getDepartmentById(this.props.match.params.id)
        .then((data) => {
          this.setState({ department: data.Data, loading: false });
        });
  }

  render() {
    const items = [{ label: "Add-Edit Department" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    departmentId: this.state.department._id || null,
                    name: this.state.department.name || "",
                    description: this.state.department.description || "",
                    currentUserId: this.state.currentUser.id,
                    isActive: this.state.department.isActive || "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Department Name is required"),
                    description: Yup.string().required(
                      "Description is required"
                    ),
                  })}
                  onSubmit={(formData, { setStatus, setSubmitting }) => {
                    setStatus();
                    departmentService.create(formData).then(
                      (user) => {
                        const { from } = this.props.location.state || {
                          from: { pathname: "/super-admin/departments" },
                        };
                        this.props.history.push(from);
                      },
                      (error) => {
                        setSubmitting(false);
                        setStatus(error);
                      }
                    );
                  }}
                  render={({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    values,
                    setFieldValue,
                  }) => (
                    <Form>
                      <div className="card-body">
                        <div className="row form-material">
                          <div className="col-md-12">
                            <div className="form-group required">
                              <label className="control-label">
                                Department Name
                              </label>
                              <Field
                                name="name"
                                type="text"
                                placeholder="Department Name"
                                className={
                                  "form-control" +
                                  (errors.name && touched.name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">
                                Description
                              </label>
                              <Field
                                name="description"
                                component="textarea"
                                rows={5}
                                placeholder="Description"
                                className={
                                  "form-control" +
                                  (errors.description && touched.description
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer accordionfooter d-flex">
                        <div style={{ marginRight: "5px" }}>
                          <Link
                            to="/super-admin/departments"
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey", color: "white" }}
                          >
                            {/* <i className="mdi mdi-chevron-left"></i> */}
                            <span className="hide-menu">Cancel</span>
                          </Link>
                        </div>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary"
                        >
                          {" "}
                          {!this.state.department._id
                            ? "Save Department"
                            : "Update Department"}{" "}
                        </button>

                        {isSubmitting && (
                          <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        )}
                      </div>
                      {status && (
                        <div className={"alert alert-danger"}>{status}</div>
                      )}
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
