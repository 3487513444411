import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { authenticationService } from "../../_services";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import swal from "sweetalert";
import queryString from "query-string";

export default class CompanyBadgeAssigment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      companies: [],
      selectedBadges: [],
      selectedCompanyId: null,
      globalFilter: null,
      assignBadgeModal: false,
      badgeCompanyLastSearched: ""
    };

    this.badgeOptions = [
      { value: "Canadian HR Awards", label: "Canadian HR Awards" },
      {
        value: "Canadian Fintech & AI Awards",
        label: "Canadian Fintech & AI Awards"
      },
      {
        value: "Canadian Business Leader Award",
        label: "Canadian Business Leader Award"
      },
      {
        value: "Strategy Marketing Awards",
        label: "Strategy Marketing Awards"
      },
      {
        value: "Surrey Business Excellence Awards (Canada)",
        label: "Surrey Business Excellence Awards (Canada)"
      },
      {
        value: "Canadian Online Publishing Awards",
        label: "Canadian Online Publishing Awards"
      },
      {
        value: "Institutional Asset Management Awards",
        label: "Institutional Asset Management Awards"
      },
      { value: "Canadian Search Awards", label: "Canadian Search Awards" },
      { value: "Women of Inspiration", label: "Women of Inspiration" }
    ];
  }

  componentDidMount() {
    this.getAllActiveCompanies();
    this.setLastSearchedValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }
  setLastSearchedValues = () => {
    const { company } = queryString.parse(this.props.location.search);

    if (company) {
      this.setState({
        globalFilter: company,
        badgeCompanyLastSearched: company
      });
    }
  };

  setFilterOnBack = () => {
    const { company } = queryString.parse(this.props.location.search);

    if (company) {
      this.setState({
        globalFilter: company,
        badgeCompanyLastSearched: company
      });
    } else {
      this.setState({
        globalFilter: "",
        badgeCompanyLastSearched: ""
      });
    }
  };

  getAllActiveCompanies = () => {
    companyService.getAllActiveCompanies().then((data) => {
      if (data && data.Status) {
        this.setState({ companies: data.Data });
      }
    });
  };

  badgeTemplates = (rowData) => {
    return (
      <div className="action-container">
        <span data-toggle="tooltip" title="View">
          {rowData?.badges?.length > 0 &&
            rowData.badges.map((badges) => {
              return (
                <div className="badge badge-pill badge-primary ml-1">
                  {badges}
                </div>
              );
            })}
        </span>
      </div>
    );
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer assign-badge-btn"
          data-toggle="tooltip"
          title="View"
          onClick={(e) =>
            this.openAssignBadgeModal(rowData._id, rowData.badges)
          }
        >
          Assign Badge
        </span>
      </div>
    );
  };

  assignBadges = () => {
    let requestData = {
      companyId: this.state.selectedCompanyId,
      currentUserId: this.state.currentUser.id,
      badges: this.state.selectedBadges.map((badge) => badge.value)
    };
    companyService.assignBadges(requestData).then((data) => {
      if (data && data.Status) {
        swal("Asssigned badges succesfully", "", "success");
        this.closeAssignBadgeModal();
        this.getAllActiveCompanies();
      }
    });
  };

  openAssignBadgeModal = (companyId, badges) => {
    let preSelectedBadges = [];
    if (badges) {
      badges.forEach((badge) => {
        let badgeFlag = false;
        this.badgeOptions.forEach((option) => {
          if (option.value === badge) {
            preSelectedBadges.push(option);
            badgeFlag = true;
          }
        });
        if (!badgeFlag) {
          preSelectedBadges.push({ value: badge, label: badge });
        }
      });
    }
    this.setState({
      assignBadgeModal: true,
      selectedBadges: preSelectedBadges,
      selectedCompanyId: companyId
    });
  };

  closeAssignBadgeModal = () => {
    this.setState({ assignBadgeModal: false });
  };

  dateTemplate = (rowData) => {
    return moment(rowData.updatedAt || rowData.createdAt).format(
      "MM/DD/YYYY hh:mm a"
    );
  };

  handleOnChange = (value) => {
    this.setState({ selectedBadges: value });
  };

  appendQueryStringOnTextChangeinURL = (company) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/assign-badge",
        search: `?company=${company}`
      });
    } else {
      this.props.history.replace({
        pathname: "/assign-badge",
        search: `?company=${company}`
      });
    }
  };

  render() {
    const items = [{ label: "Assign Badge" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    const pendingListHeader = (
      <div className="table-header">
        Pending Company Hightlight Request List
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) =>
              this.setState({ pendingListGlobalFilter: e.target.value })
            }
            placeholder="Search"
          />
        </span>
      </div>
    );

    const approvedListHeader = (
      <div className="table-header">
        Approved Company Hightlight Request List
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) =>
              this.setState({ approvedListGlobalFilter: e.target.value })
            }
            placeholder="Search"
          />
        </span>
      </div>
    );

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              {this.state.companies.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>Company List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState(
                            {
                              globalFilter: e.target.value,
                              badgeCompanyLastSearched: e.target.value
                            },
                            () => {
                              this.appendQueryStringOnTextChangeinURL(
                                this.state.badgeCompanyLastSearched
                              );
                            }
                          )
                        }
                        placeholder="Search"
                        value={this?.state?.badgeCompanyLastSearched}
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.companies}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.globalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="demographics.companyTitle"
                        header="Company Title"
                        sortable
                      ></Column>
                      {/* <Column
                        field="industry"
                        header="Industry"
                        sortable
                      ></Column> */}
                      <Column
                        field="modifiedAt"
                        header="Modified"
                        body={this.dateTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.badgeTemplates}
                        header="Badges Assigned"
                        className="text-center"
                      ></Column>
                      <Column
                        body={this.actionTemplate}
                        header="Action"
                        className="text-center"
                      ></Column>
                    </DataTable>
                  </div>
                  <Dialog
                    header="Assign Badge"
                    visible={this.state.assignBadgeModal}
                    style={{ width: "50vw" }}
                    onHide={this.closeAssignBadgeModal}
                    contentClassName="assign-badge-modal-content"
                  >
                    <div>
                      <CreatableSelect
                        value={this.state.selectedBadges}
                        onChange={this.handleOnChange}
                        isMulti
                        options={this.badgeOptions}
                      />
                      <button
                        className="mt-3 btn btn-success btn-block"
                        onClick={this.assignBadges}
                      >
                        Assign
                      </button>
                    </div>
                  </Dialog>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">No Active Companies Available</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
