import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import { authenticationService } from "../../_services";
import { connect } from "react-redux";
import {
  setDepartmentsData,
  setSelectedDepartment,
} from "../../redux-store/action";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import { departmentService } from "../../_services/superadmin/department.service";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setDepartments: (data) => {
      dispatch(setDepartmentsData(data));
    },

    setSelectedDepartment: (data) => {
      dispatch(setSelectedDepartment(data));
    },
  };
}

class AppTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      currentDepartment: authenticationService.currentDepartmentValue,
      departments: [],
      currentDepartmentName: "No Department",
      dropdownOpen: false,
      departmentOpen: false,
    };
  }

  toggle = (t) => {
    switch (t) {
      case 1:
        this.setState({
          dropdownOpen: !this.state.dropdownOpen,
        });
        break;
      case 2:
        this.setState({
          departmentOpen: !this.state.departmentOpen,
        });
        break;

      default:
        break;
    }
  };

  componentDidMount() {
    this.getAllDepartments();
  }

  // getAllDepartment = () => {
  //   departmentService.getAll().then((data) => {
  //     if (data && data.Status) {
  //       console.log(data);
  //       this.setState({
  //         department: data.Data,
  //         selected_department: data.Data.length > 0 ? data.Data[0]._id : "",
  //       });
  //     }
  //   });
  // };

  getAllDepartments() {
    // alert('getting all depts')
    departmentService.getAll().then((data) => {
      let departments = [];
      let currentDepartmentName = "";
      data &&
        this.state.currentUser &&
        data.Data &&
        data.Data.map((item, i) => {
          if (this.state.currentUser.departments.indexOf(item._id) !== -1) {
            departments.push(item);
          }
          if (item._id == this.state.currentDepartment) {
            currentDepartmentName = item.name;
            authenticationService.setDepartmentName(item.name);
            this.props.setSelectedDepartment(item.name);
          }
        });
      this.setState({ departments: departments, currentDepartmentName });
      this.props.setDepartments(data.Data);
    });
  }

  setCurrentDepartment(departmentId, currentDepartmentName) {
    if (departmentId == this.state.currentDepartment) {
      console.log("This is true!!!!!!");
      return false;
    }
    authenticationService.changeDepartment(departmentId, currentDepartmentName);
    this.props.setSelectedDepartment(currentDepartmentName);
    this.setState({ currentDepartmentName, currentDepartment: departmentId });
  }

  render() {
    const {
      currentUser,
      dropdownOpen,
      currentDepartment,
      currentDepartmentName,
      departments,
      selected_department,
      departmentOpen,
    } = this.state;

    return (
      <div className="layout-topbar clearfix">
        <button
          className="p-link layout-menu-button"
          onClick={this.props.onToggleMenu}
        >
          <span className="pi pi-bars" />
        </button>

        <div className="layout-topbar-icons" style={{ display: "flex" }}>
          <Dropdown isOpen={dropdownOpen} toggle={() => this.toggle(1)}>
            <DropdownToggle className="p-link custom-top-bar-dropdown">
              <div>
                <span className="welcome-header">
                  Welcome, {currentUser.firstName}
                </span>
                {currentUser.profileImageUrl != "" && (
                  <img
                    src={currentUser.profileImageUrl}
                    className="company-profile-logo-header"
                    alt="logo"
                  />
                )}
              </div>
            </DropdownToggle>
            <DropdownMenu className="small-menu" style={{ minWidth: "190px" }}>
              <div style={{ textAlign: "center" }}>
                {currentUser.profileImageUrl != "" && (
                  <img
                    src={currentUser.profileImageUrl}
                    className="menu-item-logo"
                    alt="logo"
                  />
                )}
                <div style={{ fontSize: 14, fontWeight: "bold" }}>
                  {currentUser.firstName} {currentUser.lastName}
                </div>
                <div style={{ fontSize: 14 }}>{currentUser.email}</div>
              </div>
              <DropdownItem divider />
              <div
                style={{ marginLeft: 8, cursor: "pointer" }}
                onClick={(e) => this.toggle()}
              >
                <Link to="/change-password">
                  {" "}
                  <i className="pi pi-lock" style={{ marginRight: 8 }}></i>
                  Change Password
                </Link>
              </div>
              <DropdownItem divider />
              <div
                style={{ marginLeft: 8, cursor: "pointer" }}
                onClick={(e) => this.props.logout(e)}
              >
                <i className="pi pi-power-off" style={{ marginRight: 8 }}></i>
                Logout
              </div>
            </DropdownMenu>
          </Dropdown>
          <Dropdown isOpen={departmentOpen} toggle={() => this.toggle(2)}>
            <DropdownToggle className="p-link mt-1 custom-top-bar-dropdown">
              <div>
                <span style={{ marginRight: 5 }}>{currentDepartmentName}</span>
              </div>
            </DropdownToggle>
            <DropdownMenu className="small-menu" style={{ minWidth: "190px" }}>
              {departments.map((el, i) => {
                return (
                  <DropdownItem
                    key={i}
                    className="custom-dropdown-btn"
                    onClick={() => {
                      this.setCurrentDepartment(el._id, el.name);
                      //this.setState({ selected_department: el._id });
                      this.toggle(2);
                    }}
                  >
                    {el.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>

          <div className="p-link">
            <img
              src={"/assets/images/canada.png"}
              className="header-flag"
              alt="logo"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppTopbar);
