import React from "react";
import { getFieldValue } from "../_helpers/_helperFunctions";
export const CustomTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { initialValues }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  isEdit,
  ...props
}) =>
  isEdit ? (
    <input type="text" {...field} {...props} />
  ) : (
    <b className="mx-1">{getFieldValue(initialValues, field.name)}</b>
  );
