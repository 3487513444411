import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "primereact/tooltip";
import {
  faPencilAlt,
  faEye,
  faThumbsUp,
  faThumbsDown,
  faHandPaper
} from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "primereact/dialog";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { authenticationService } from "../../_services";
import moment from "moment";
import swal from "sweetalert";
import { companiesData } from "../../_helpers/companyData";
import { apmaServices } from "../../_services/admin/apma.service";
import { Link } from "react-router-dom";
let companykeys = Object.keys(companiesData);
export default class APMACompanyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileRequests: companykeys.map((key) => {
        return companiesData[key];
      }),
      approvedRequests: companykeys.map((key) => {
        return companiesData[key];
      }),
      currentUser: authenticationService.currentUserValue,
      selectedProfileRequest: {},
      pendingListGlobalFilter: null,
      approvedListGlobalFilter: null,
      profileViewModal: false,
      days: 15,
      pastCompanyList: [],
      companyList: []
    };
  }

  componentDidMount() {
    // this.getAllPendingProfileRequest();
    // this.getAllApprovedProfileRequest();
    //this.getAllCompanies();
    this.getLatestCompanies();
  }

  getAllPendingProfileRequest = () => {
    companyService.getAllPendingProfileRequest().then((data) => {
      if (data && data.Status) {
        this.setState({ profileRequests: data.Data });
      }
    });
  };

  getAllApprovedProfileRequest = () => {
    companyService.getAllApprovedProfileRequest().then((data) => {
      if (data && data.Status) {
        this.setState({ approvedRequests: data.Data });
      }
    });
  };

  approveRequest = (profileRequestId) => {
    let requestData = {
      profileRequestId,
      currentUserId: this.state.currentUser.id
    };
    companyService.approveProfileRequest(requestData).then((data) => {
      if (data && data.Status) {
        this.getAllPendingProfileRequest();
        this.getAllApprovedProfileRequest();
        swal(data.Data.message, "", "success");
      }
    });
  };

  getAllCompanies = (days) => {
    apmaServices
      .getAllCompanies(new Date().getTime(), days || this.state.days)
      .then((data) => {
        this.setState({ pastCompanyList: data.Data });
      });
  };

  getLatestCompanies = () => {
    apmaServices.getAllCompanies().then((data) => {
      this.setState({ companyList: data.Data });
    });
  };

  rejectRequest = (profileRequestId) => {
    let requestData = {
      profileRequestId,
      currentUserId: this.state.currentUser.id
    };

    swal({
      title: "Are you sure?",
      text: "You want to reject this request.",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        companyService.rejectProfileRequest(requestData).then((data) => {
          if (data && data.Status) {
            this.getAllPendingProfileRequest();
            swal("Profile Request Rejected", "", "success");
          }
        });
      }
    });
  };

  getProfileRequestById = (profileRequestId) => {
    companyService.getProfileRequestById(profileRequestId).then((data) => {
      if (data && data.Status) {
        this.setState({
          selectedProfileRequest: data.Data,
          profileViewModal: true
        });
      }
    });
  };

  toggleProfileView = (profileRequestId) => {
    this.getProfileRequestById(profileRequestId);
  };

  actionTemplate = (rowData) => {
    return (
      /* <div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.toggleProfileView(rowData._id)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>

        
      </div>*/

      <div className="d-flex justify-content-start">
        <Link
          to={`/apma-profile/${rowData._id}`}
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>

        {rowData?.mutualNDA?.isCompleted ? (
          <div className="ml-2">
            <span className="badge badge-success">NDA</span>
          </div>
        ) : (
          <div className="ml-2">
            <span className="badge"> &nbsp; &nbsp; &nbsp; &nbsp; </span>
          </div>
        )}

        {rowData?.arrowpatnerhsips?.isCompleted ? (
          <div className="ml-2">
            <span className="badge badge-warning">APA</span>
          </div>
        ) : (
          <div className="ml-2">
            <span className="badge"> &nbsp; &nbsp; &nbsp; &nbsp; </span>
          </div>
        )}
        {rowData?.rfps?.isCompleted ? (
          <div className="ml-2">
            <span className="badge badge-primary">RFP</span>
          </div>
        ) : (
          <div className="ml-2">
            <span className="badge"> &nbsp; &nbsp; &nbsp; &nbsp; </span>
          </div>
        )}
      </div>
    );
  };

  approveActionTemplate = (rowData) => {
    return (
      /*<div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.toggleProfileView(rowData._id)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
      </div>*/
      <div className="d-flex justify-content-start">
        <Link
          to={`/apma-profile/${rowData._id}`}
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>
        {rowData?.mutualNDA?.isCompleted ? (
          <div className="ml-2">
            <span className="badge badge-success">NDA</span>
          </div>
        ) : null}
        {rowData?.arrowpatnerhsips?.isCompleted ? (
          <div className="ml-2">
            <span className="badge badge-warning">APA</span>
          </div>
        ) : null}
        {rowData?.rfps?.isCompleted ? (
          <div className="ml-2">
            <span className="badge badge-primary">RFP</span>
          </div>
        ) : null}
      </div>
    );
  };

  openProfileModal = () => {
    this.setState({ profileViewModal: true });
  };

  closeProfileModal = () => {
    this.setState({ profileViewModal: false });
  };

  dateTemplate = (rowData) => {
    return moment(rowData.updatedAt).format("MM/DD/YYYY hh:mm a");
  };
  handleDaysChange = (e) => {
    this.setState({ days: e.target.value });
    this.getAllCompanies(e.target.value);
  };
  render() {
    let { selectedProfileRequest } = this.state;
    const items = [{ label: "Company Profile List" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              {this.state.profileRequests.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>APMA Company List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState({
                            pendingListGlobalFilter: e.target.value
                          })
                        }
                        placeholder="Search"
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.companyList}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.pendingListGlobalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="companyTitle"
                        header="Company Name"
                        sortable
                      ></Column>
                      <Column
                        field="industry"
                        header="Industry/Sector"
                        sortable
                      ></Column>
                      <Column
                        field="updatedAt"
                        header="Modified At"
                        body={this.dateTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.actionTemplate}
                        header="Action"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">No Pending Request Available</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
