import React, { Component } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { authenticationService } from "../../_services";
import { inviteService } from "../../_services/admin/invite.service";
import { BreadCrumb } from "primereact/breadcrumb";
import swal from "sweetalert";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
export default class AddCompanyData extends Component {
  constructor() {
    super();
    this.state = {
      currentUser: authenticationService.currentUserValue,
      manuallyInvitedCompanies: [],
    };
  }

  componentDidMount() {
    this.getManuallyAddedCompanies();
  }

  getManuallyAddedCompanies = () => {
    console.log("called");
    inviteService.getManuallyAddedCompanies().then((data) => {
      if (data && data.Status) {
        this.setState({ manuallyInvitedCompanies: data.Data });
      }
    });
  };

  dateTemplate = (rowData) => {
    return moment(rowData.updatedAt || rowData.createdAt).format(
      "MM/DD/YYYY hh:mm a"
    );
  };

  deleteInvitedCompany = (inviteCompanyId) => {
    inviteService.deleteInvitedCompany(inviteCompanyId).then((data) => {
      if (data && data.Status) {
        swal(data.Data.Message, "", "success");
      }
      this.getManuallyAddedCompanies();
    });
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.deleteInvitedCompany(rowData._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </div>
    );
  };

  render() {
    const items = [{ label: "Add Company Data" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <div className="col-md-6">
                <Formik
                  enableReinitialize={true}
                  validationSchema={Yup.object().shape({
                    companyTitle: Yup.string().required(
                      "Company Title is required"
                    ),
                    email: Yup.string()
                      .email("Invalid-Email")
                      .required("Email is required"),
                  })}
                  initialValues={{
                    companyTitle: "",
                    email: "",
                    industry: "",
                    currentUser: this.state.currentUser.id,
                  }}
                  onSubmit={(
                    formData,
                    { setStatus, setSubmitting, resetForm }
                  ) => {
                    setSubmitting(true);
                    inviteService.inviteCompanyData(formData).then((data) => {
                      if (data) {
                        setSubmitting(false);
                        swal("Company Data Saved", " ", "success");
                        this.getManuallyAddedCompanies();
                        resetForm({
                          values: {
                            companyTitle: "",
                            email: "",
                            industry: "",
                          },
                        });
                      }
                    });
                  }}
                >
                  {({ errors, touched, isSubmitting }) => {
                    return (
                      <Form>
                        <div className="form-group required">
                          <label className="control-label">Company Title</label>
                          <Field
                            name="companyTitle"
                            type="text"
                            placeholder="Company Title"
                            className={
                              "form-control" +
                              (errors.companyTitle && touched.companyTitle
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="companyTitle"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group required">
                          <label className="control-label">Company Email</label>
                          <Field
                            name="email"
                            type="text"
                            placeholder="Company Email"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group required">
                          <label className="control-label">Industry</label>
                          <Field
                            name="industry"
                            type="text"
                            placeholder="Industry"
                            className={
                              "form-control" +
                              (errors.industry && touched.industry
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="industry"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group text-center mb-0 m-t-20">
                          <div className="offset-md-3 col-md-6 col-xs-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-lg btn-block text-uppercase pt-2"
                            >
                              {"Save"}
                              {isSubmitting && (
                                <span>
                                  {" "}
                                  <i className="pi pi-spin pi-spinner"></i>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <div className="card">
              <div className="datatable-filter">
                {this.state.manuallyInvitedCompanies.length ? (
                  <div className="card">
                    <div className="mb-3 table-header">
                      <h2>Invited Companies</h2>
                      <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                          type="search"
                          onInput={(e) =>
                            this.setState({
                              globalFilter: e.target.value,
                            })
                          }
                          placeholder="Search"
                        />
                      </span>
                    </div>
                    <div className="custom-table-responsive ">
                      <DataTable
                        value={this.state.manuallyInvitedCompanies}
                        className="p-datatable-striped"
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        globalFilter={this.state.globalFilter}
                        rowsPerPageOptions={[10, 20, 50]}
                      >
                        <Column
                          field="companyTitle"
                          header="Company Title"
                          sortable
                        ></Column>
                        <Column
                          field="email"
                          header="Email Id"
                          body={this.companiesCount}
                          sortable
                        ></Column>
                        <Column
                          field="createdAt"
                          header="Created"
                          body={this.dateTemplate}
                          sortable
                        ></Column>
                        <Column
                          header="Action"
                          body={this.actionTemplate}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <h3 className="text-center">
                      No Manually Added Companies Available
                    </h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
