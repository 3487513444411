import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faEye,
  faThumbsUp,
  faThumbsDown,
  faHandPaper,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "primereact/dialog";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { highlightService } from "../../_services/admin/highlight.service";
import { dateTemplate } from "../../_helpers/_helperFunctions";
import moment from "moment";
import swal from "sweetalert";

export default class CompanyHighlightsRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      pendingHighlights: [],
      pendingListGlobalFilter: null,
      approvedHighlights: [],
      approvedListGlobalFilter: null,
      highlightViewModal: false,
      selectedHightlight: {},
      unPublishedCompanyList: [],
      unPublishListGlobalFilter: null,
      holdCompanies: [],
      holdCompaniesGlobalFilter: null
    };
  }

  componentDidMount() {
    this.getPendingHighlights();
    this.getApprovedHighlights();
    this.getAllUnpublishedHighlights();
    this.getAllHoldAnnouncement();
  }

  getPendingHighlights = () => {
    highlightService.getPendingHighlights().then((data) => {
      if (data && data.Status) {
        this.setState({ pendingHighlights: data.Data });
      }
    });
  };

  getAllHoldAnnouncement = () => {
    highlightService.getAllHoldCompanies().then((data) => {
      if (data && data.Status) {
        this.setState({ holdCompanies: data.Data });
      }
    });
  };

  getApprovedHighlights = () => {
    highlightService.getApprovedHighlights().then((data) => {
      if (data && data.Status) {
        this.setState({ approvedHighlights: data.Data });
      }
    });
  };

  getAllUnpublishedHighlights = () => {
    highlightService.getAllUnpublishedHighlights().then((data) => {
      if (data && data.Status) {
        console.log(data);
        this.setState({ unPublishedCompanyList: data.Data });
      }
    });
  };

  publishAndUnPublishHighlights = (id, status) => {
    let requestData = {
      id,
      status,
      currentUserId: this.state.currentUser.id
    };
    highlightService.publisheAndUnPublishCompany(requestData).then(() => {
      this.getAllUnpublishedHighlights();
      this.getApprovedHighlights();
    });
  };

  deleteAnnoucement = (id) => {
    let requestData = {
      id
    };
    highlightService.deleteHighlight(requestData).then(() => {
      this.getAllUnpublishedHighlights();
      this.getApprovedHighlights();
    });
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.openHighlightModal(rowData)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
        <span
          className="cursor-pointer text-primary"
          data-toggle="tooltip"
          title="Edit"
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </span>
        <span
          className="cursor-pointer text-success"
          data-toggle="tooltip"
          title="Approve"
          onClick={(e) => this.approveHighlight(rowData._id)}
        >
          <FontAwesomeIcon icon={faThumbsUp} />
        </span>
        <span
          className="cursor-pointer text-danger"
          data-toggle="tooltip"
          title="Disapprove"
          onClick={(e) => this.rejectHighlight(rowData._id)}
        >
          <FontAwesomeIcon icon={faThumbsDown} />
        </span>
        <span
          className="cursor-pointer text-warning"
          data-toggle="tooltip"
          title="Put on Hold"
          onClick={(e) => this.holdHighlight(rowData._id)}
        >
          <FontAwesomeIcon icon={faHandPaper} />
        </span>
      </div>
    );
  };

  holdActionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer text-primary"
          data-toggle="tooltip"
          title="Edit"
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </span>
        <span
          className="cursor-pointer text-success"
          data-toggle="tooltip"
          title="Approve"
          onClick={(e) => this.approveHighlight(rowData._id)}
        >
          <FontAwesomeIcon icon={faThumbsUp} />
        </span>
        <span
          className="cursor-pointer text-danger"
          data-toggle="tooltip"
          title="Disapprove"
          onClick={(e) => this.rejectHighlight(rowData._id)}
        >
          <FontAwesomeIcon icon={faThumbsDown} />
        </span>
      </div>
    );
  };

  approveActionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.openHighlightModal(rowData)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          onClick={(e) => {
            this.publishAndUnPublishHighlights(rowData._id, false);
          }}
        >
          UnPublish
        </button>
      </div>
    );
  };

  unPublishActionTemplate = (rowData) => {
    console.log(rowData);
    return (
      <div className="action-container">
        <span
          className="cursor-pointer"
          onClick={(e) => this.openHighlightModal(rowData)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
        <button
          type="button"
          class="btn btn-success btn-sm"
          onClick={(e) => {
            this.publishAndUnPublishHighlights(rowData._id, true);
          }}
        >
          Publish
        </button>

        <span
          className="cursor-pointer "
          style={{ color: "red" }}
          onClick={() => {
            swal({
              title: "Are you sure?",
              text: "You want to delete this highlights.",
              icon: "warning",
              buttons: true,
              dangerMode: true
            }).then((willDelete) => {
              if (willDelete) {
                this.deleteAnnoucement(rowData._id);
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </div>
    );
  };

  openHighlightModal = (highlightData) => {
    this.setState({
      highlightViewModal: true,
      selectedHightlight: highlightData
    });
  };

  closeHighlightModal = () => {
    this.setState({ highlightViewModal: false, selectedHightlight: {} });
  };

  dateTemplate = (rowData) => {
    return moment(rowData.createdAt).format("MM/DD/YYYY hh:mm a");
  };

  updatedAtTemplate = (rowData) => {
    return moment(rowData.updatedAt).format("MM/DD/YYYY hh:mm a");
  };

  approveHighlight = (highlightId) => {
    let requestData = {
      highlightId,
      currentUserId: this.state.currentUser.id
    };
    highlightService.approveHighlight(requestData).then((data) => {
      if (data && data.Status) {
        this.getPendingHighlights();
        this.getApprovedHighlights();
        this.getAllHoldAnnouncement();
        swal("Highlight Approved", "", "success");
      }
    });
  };

  rejectHighlight = (highlightId) => {
    let requestData = {
      highlightId,
      currentUserId: this.state.currentUser.id
    };

    swal({
      title: "Are you sure?",
      text: "You want to reject this highlight.",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        highlightService.rejectHighlight(requestData).then((data) => {
          if (data && data.Status) {
            swal("Profile Request Rejected", "", "success");
            this.getPendingHighlights();
            this.getApprovedHighlights();
          }
        });
      }
    });
  };

  holdHighlight = (highlightId) => {
    let requestData = {
      highlightId,
      currentUserId: this.state.currentUser.id,
      status: 4
    };

    swal({
      title: "Are you sure?",
      text: "You want to hold this highlight.",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        highlightService.holdUnHoldHighlight(requestData).then((data) => {
          if (data && data.Status) {
            swal("Profile Request on Hold", "", "success");
            this.getPendingHighlights();
            this.getApprovedHighlights();
            this.getAllHoldAnnouncement();
          }
        });
      }
    });
  };

  render() {
    const { selectedHightlight } = this.state;
    const items = [{ label: "Company Announcements List" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    const pendingListHeader = (
      <div className="table-header">
        Pending Company Announcements Request List
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) =>
              this.setState({ pendingListGlobalFilter: e.target.value })
            }
            placeholder="Search"
          />
        </span>
      </div>
    );

    const approvedListHeader = (
      <div className="table-header">
        Approved Company Hightlight Request List
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) =>
              this.setState({ approvedListGlobalFilter: e.target.value })
            }
            placeholder="Search"
          />
        </span>
      </div>
    );

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              {this.state.pendingHighlights.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>Pending Company Announcements Request List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState({
                            pendingListGlobalFilter: e.target.value
                          })
                        }
                        placeholder="Search"
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.pendingHighlights}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.pendingListGlobalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="companyDetails.demographics.companyTitle"
                        header="Company Title"
                        sortable
                      ></Column>
                      <Column
                        field="createdAt"
                        header="Modified"
                        body={this.dateTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.actionTemplate}
                        header="Action"
                        className="text-center"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">
                    No Pending Announcements Available
                  </h3>
                </div>
              )}
            </div>
          </div>

          <div className="p-col-12">
            <div className="datatable-filter">
              {this.state.approvedHighlights.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>Approved Company Announcements Request List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState({
                            approvedListGlobalFilter: e.target.value
                          })
                        }
                        placeholder="Search"
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.approvedHighlights}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.approvedListGlobalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="companyDetails.demographics.companyTitle"
                        header="Company Title"
                        sortable
                      ></Column>
                      {/* <Column
                        field="companyDetails.industry"
                        header="Industry"
                        sortable
                      ></Column> */}
                      <Column
                        field="updatedAt"
                        header="Modified"
                        body={this.updatedAtTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.approveActionTemplate}
                        header="Action"
                        className="text-center"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">
                    No Approved Announcements Available
                  </h3>
                </div>
              )}

              {/* Unpublished Highlights */}
              {this.state.unPublishedCompanyList.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>Unpublished Company Announcements Request List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState({
                            unPublishListGlobalFilter: e.target.value
                          })
                        }
                        placeholder="Search"
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.unPublishedCompanyList}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.unPublishListGlobalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="companyDetails.demographics.companyTitle"
                        header="Company Title"
                        sortable
                      ></Column>
                      {/* <Column
                        field="companyDetails.industry"
                        header="Industry"
                        sortable
                      ></Column> */}
                      <Column
                        field="updatedAt"
                        header="Modified"
                        body={this.updatedAtTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.unPublishActionTemplate}
                        header="Action"
                        className="text-center"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">
                    No Unpublished Company Announcements Request List
                  </h3>
                </div>
              )}

              {this.state.holdCompanies.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>On Hold Announcements Request List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState({
                            holdCompaniesGlobalFilter: e.target.value
                          })
                        }
                        placeholder="Search"
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.holdCompanies}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.holdCompaniesGlobalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="companyDetails.demographics.companyTitle"
                        header="Company Title"
                        sortable
                      ></Column>
                      {/* <Column
                        field="companyDetails.industry"
                        header="Industry"
                        sortable
                      ></Column> */}
                      <Column
                        field="updatedAt"
                        header="Modified"
                        body={this.updatedAtTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.holdActionTemplate}
                        header="Action"
                        className="text-center"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">
                    No Hold Announcements Available
                  </h3>
                </div>
              )}
              <Dialog
                header="Company Announcements Details"
                visible={this.state.highlightViewModal}
                style={{ width: "90vw" }}
                onHide={this.closeHighlightModal}
              >
                <div className="company-info-details">
                  <div className="company-info-box p-2">
                    <h4 className="py-2">Type Of Announcements</h4>
                    <p>
                      {selectedHightlight.highlightType === "other"
                        ? `Other ${selectedHightlight.otherCategory}`
                        : selectedHightlight.highlightType}
                    </p>
                  </div>

                  <div className="company-info-box p-2">
                    <h4 className="py-2">Headline</h4>
                    <p>{selectedHightlight.headline}</p>
                  </div>

                  <div className="company-info-box p-2">
                    <h4 className="py-2">Media Contact</h4>
                    <p>{selectedHightlight.pressContact}</p>
                  </div>
                  <div className="company-info-box p-2">
                    <h4 className="py-2">Details</h4>
                    <p>{selectedHightlight.bodyCopy}</p>
                  </div>

                  {/* <div className="company-info-box p-2">
                    <h4 className="py-2">Point of Contact</h4>
                    <p>{selectedHightlight.pointOfContact}</p>
                  </div> */}
                  <div className="company-info-box p-2">
                    <h4 className="py-2">Email address</h4>
                    <p>{selectedHightlight.emailAddress}</p>
                  </div>
                  <div className="company-info-box p-2">
                    <h4 className="py-2">Phone Number</h4>
                    <p>{selectedHightlight.phoneNumber}</p>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </>
    );
  }
}
