import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { companyService } from "../../_services/admin/company.service";
import swal from "sweetalert";
import moment from "moment";
import queryString from "query-string";

export default class CompanyFeaturedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      companies: [],
      globalFilter: null,
      loading: true,
      featuredCompanyLastSearch: ""
    };
  }
  componentDidMount() {
    this.getAllCompanies();
    this.setLastSearchedValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setLastSearchedValues = () => {
    const { f: featuredCompany } = queryString.parse(
      this.props.location.search
    );

    if (featuredCompany) {
      this.setState({
        globalFilter: featuredCompany,
        featuredCompanyLastSearch: featuredCompany
      });
    }
  };

  setFilterOnBack = () => {
    const { f: featuredCompany } = queryString.parse(
      this.props.location.search
    );

    if (featuredCompany) {
      this.setState({
        globalFilter: featuredCompany,
        featuredCompanyLastSearch: featuredCompany
      });
    } else {
      this.setState({
        globalFilter: "",
        featuredCompanyLastSearch: ""
      });
    }
  };

  companyList = [
    {
      companyTitle: "Marks and Sons",
      industry: "Internet Solution",
      createdAt: "09/12/2020 02:59:32 AM"
    },
    {
      companyTitle: "Gleichner - Gerlach",
      industry: "E-Commerce",
      createdAt: "09/24/2020 09:47:54 PM"
    },
    {
      companyTitle: "Padberg - Wiza",
      industry: "E-Business",
      createdAt: "09/26/2020 03:00:42 AM"
    },
    {
      companyTitle: "Rice Inc",
      industry: "Food Tech",
      createdAt: "09/29/2020 06:55:03 AM"
    },
    {
      companyTitle: "Kohler Inc",
      industry: "Networks",
      createdAt: "10/01/2020 10:58:29 AM"
    }
  ];

  getAllCompanies = () => {
    companyService.getAllCompanies().then((data) => {
      if (data && data.Status) {
        this.setState({ companies: data.Data, loading: false });
      }
    });
  };

  tagCompanyAsFeatured = (companyId) => {
    let requestData = { currentUserId: this.state.currentUser.id, companyId };

    companyService
      .tagCompanyAsFeatured(requestData)
      .then((data) => {
        if (data.Status) {
          let featuredCompanies = Array.from(this.state.companies);
          featuredCompanies.forEach((company) => {
            if (companyId === company._id) {
              company.isFeatured = true;
            }
          });
          this.setState({ companies: featuredCompanies });

          swal("Company has been Featured", "", "success");
        }
      })
      .catch((error) => {
        console.log(error);
        swal("Already 6 Company Featured", "", "error");
      });
  };

  tagCompanyAsUnFeatured = (companyId) => {
    let requestData = { currentUserId: this.state.currentUser.id, companyId };

    companyService
      .tagCompanyAsUnFeatured(requestData)
      .then((data) => {
        if (data.Status) {
          let unfeaturedCompanies = Array.from(this.state.companies);
          unfeaturedCompanies.forEach((company) => {
            if (companyId === company._id) {
              company.isFeatured = false;
            }
          });

          this.setState({ companies: unfeaturedCompanies });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container featured-action-btn">
        <InputSwitch
          checked={rowData.isFeatured === true}
          className="featured-status"
          tooltip={rowData.isFeatured ? "Featured" : "Not Featured"}
          onChange={(e) => {
            if (rowData.isFeatured) {
              return this.tagCompanyAsUnFeatured(rowData._id);
            } else {
              return this.tagCompanyAsFeatured(rowData._id);
            }
          }}
        />
      </div>
    );
  };

  dateTemplate = (rowData) => {
    return moment(rowData.createdAt).format("MM/DD/YYYY hh:mm a");
  };

  appendQueryStringOnTextChangeinURL = (featured) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/featured-companies",
        search: `?f=${featured}`
      });
    } else {
      this.props.history.replace({
        pathname: "/featured-companies",
        search: `?f=${featured}`
      });
    }
  };

  render() {
    const items = [{ label: "Featured Companies" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    const { featuredCompanyLastSearch, companies, globalFilter } = this.state;

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />

        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="mb-3 table-header">
                  <h2>Featured Companies</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState(
                          {
                            globalFilter: e.target.value,
                            featuredCompanyLastSearch: e.target.value
                          },
                          () => {
                            this.appendQueryStringOnTextChangeinURL(
                              this.state.featuredCompanyLastSearch
                            );
                          }
                        )
                      }
                      placeholder="Search"
                      value={featuredCompanyLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={companies}
                    className="p-datatable-striped"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={globalFilter}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    {/* <Column
                      field="industry"
                      header="Industry"
                      sortable
                    ></Column> */}
                    <Column
                      field="createdAt"
                      header="Created"
                      body={this.dateTemplate}
                      sortable
                    ></Column>
                    <Column body={this.actionTemplate} header="Action"></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
