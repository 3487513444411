import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";
export const departmentService = {
  getAll,
  getDepartmentById,
  create,
  update,
  deleteDepartment,
  getSubDepartmentsByDepartmentId,
  getSubDepartmentsByDepartmentIdList,
};

function getAll() {
  const requestOptions = { method: "GET", headers: authHeader() };
  console.log("requestOptions: ", requestOptions);
  return fetch(`${config.apiUrl}/api/v1/departments`, requestOptions).then(
    handleResponse
  );
}

function getDepartmentById(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/departments/${id}`,
    requestOptions
  ).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/departments/create`,
    requestOptions
  ).then(handleResponse);
}

function update(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data),
  };
  return fetch(
    `${config.apiUrl}/api/v1/departments/update`,
    requestOptions
  ).then(handleResponse);
}

function deleteDepartment(departmentId, currentUserId) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData({ departmentId, currentUserId }),
  };
  return fetch(
    `${config.apiUrl}/api/v1/departments/delete`,
    requestOptions
  ).then(handleResponse);
}

function getSubDepartmentsByDepartmentId(departmentId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.apiUrl}/api/v1/departments/${departmentId}/sub-departments`,
    requestOptions
  ).then(handleResponse);
}

function getSubDepartmentsByDepartmentIdList(departmentIdList) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(departmentIdList),
  };
  return fetch(
    `${config.apiUrl}/api/v1/departments/getSubDepartments`,
    requestOptions
  ).then(handleResponse);
}
