import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { authenticationService } from "../../_services/authentication.service";
import { Link } from "react-router-dom";
import queryString from "query-string";
export class PublishCompanyEditDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      publishedCompaniesGlobalFilter: null,
      publishedCompanies: [],
      companyNameLastSearch: ""
    };
  }

  componentDidMount() {
    this.getAllPublishedCompanies();
    this.setInitialFilteredValueInSearchBar();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setInitialFilteredValueInSearchBar = () => {
    const { name } = queryString.parse(this.props.location.search);
    if (name) {
      this.setState({
        publishedCompaniesGlobalFilter: name,
        companyNameLastSearch: name
      });
    }
  };

  setFilterOnBack = () => {
    const { name } = queryString.parse(this.props.location.search);

    if (name) {
      this.setState({
        publishedCompaniesGlobalFilter: name,
        companyNameLastSearch: name
      });
    } else {
      this.setState({
        publishedCompaniesGlobalFilter: "",
        companyNameLastSearch: ""
      });
    }
  };

  getAllPublishedCompanies = () => {
    companyService.getAllPublishedCompanies().then((data) => {
      this.setState({ publishedCompanies: data.Data });
    });
  };

  editPublishCompanyDetails = (rowData) => {
    return (
      <div className="action-container text-danger">
        <span className="cursor-pointer">
          {this.state.currentUser.role == 2 ? (
            <Link to={`/change-user-details/${rowData._id}`}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Link>
          ) : (
            <Link to={`/super-admin/change-user-details/${rowData._id}`}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Link>
          )}
        </span>
      </div>
    );
  };
  appendQueryStringOnTextChangeinURL = (queryString) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/edit-user-details",
        search: `?name=${queryString}`
      });
    } else {
      this.props.history.replace({
        pathname: "/edit-user-details",
        search: `?name=${queryString}`
      });
    }
  };

  render() {
    const items = [{ label: "Edit User Details" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="mb-3 table-header">
                  <h2>Edit User Details</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) => {
                        this.setState({
                          publishedCompaniesGlobalFilter: e.target.value,
                          companyNameLastSearch: e.target.value
                        });
                        this.appendQueryStringOnTextChangeinURL(
                          encodeURIComponent(e.target.value)
                        );
                      }}
                      placeholder="Search"
                      value={this.state.companyNameLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={this.state.publishedCompanies}
                    className="p-datatable-striped"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={this.state.publishedCompaniesGlobalFilter}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    {/* <Column
                      field="industry"
                      header="Industry"
                      sortable
                    ></Column> */}
                    <Column field="email" header="Email" sortable></Column>
                    <Column
                      body={this.editPublishCompanyDetails}
                      header="Action"
                      className="text-center"
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PublishCompanyEditDetails;
