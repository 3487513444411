import { authenticationService } from '../_services';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if(currentUser && currentUser.token){
        return { 
            Authorization: `Bearer ${currentUser.token}`, 
            "Content-Type": `application/json`,
            isenc:localStorage.getItem('isenc')?parseInt(localStorage.getItem('isenc')):0 
        };
    } else {
        return {};
    }
}