import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { departmentService } from "../../_services/superadmin/department.service";
import { userService } from "../../_services/superadmin/user.service";
import Preloader from "../../components/Preloader";
import moment from "moment";
import swal from "sweetalert";

export default class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      users: [],
      globalFilter: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.getAllDepartments().then(() => {
      this.getAllUsers();
    });
  }

  getAllDepartments() {
    return departmentService.getAll().then((data) => {
      if (data && data.Data) {
        this.setState({ departments: data.Data, loading: false });
      }
    });
  }

  getAllUsers() {
    userService.getAll(this.state.currentUser.id).then((data) => {
      if (data && data.Status) {
        this.setState({ users: data.Data, loading: false });
      }
    });
  }

  delete(departmentId) {
    swal({
      title: "Are you sure?",
      text: "You want to delete this user",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // departmentService
        //   .deleteDepartment(departmentId, this.state.currentUser.id)
        //   .then((response) => {
        //     response.Status && this.getAllDepartments();
        //     swal("Department Deleted Succesfully!", "", "success");
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     swal("Oops Something Went Wrong!", "", "error");
        //   });
      }
    });
  }

  dateTemplate = (rowData) => {
    return moment(rowData.createdAt).format("MM/DD/YYYY hh:mm a");
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <Link to={`/super-admin/user/edit/${rowData._id}`}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </Link>

        <span
          className="cursor-pointer text-danger"
          onClick={(e) => this.delete(rowData._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </div>
    );
  };

  accessTemplate = (rowData) => {
    return rowData.accessType === 1
      ? "Read"
      : rowData.accessType === 2
      ? "ReadWrite"
      : "Admin";
  };

  roleTemplate = (rowData) => {
    return rowData.role === 1 ? "Super Admin" : "Admin";
  };

  departmentTemplate = (rowData) => {
    let departmentName = [];
    rowData.department.filter((k) => {
      this.state.departments.filter((t) => {
        if (t._id.indexOf(k) !== -1) departmentName.push(t.name);
      });
    });
    return departmentName.join(", ");
  };

  render() {
    const { loading } = this.state;
    const items = [{ label: "Users" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        {loading && <Preloader />}
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="text-right mb-2">
                  <Link to="/super-admin/user/add" className="btn btn-primary">
                    Add User
                  </Link>
                </div>
                <div className="mb-3 table-header">
                  <h2>Users</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState({ globalFilter: e.target.value })
                      }
                      placeholder="Search"
                    />
                  </span>
                </div>
                <DataTable
                  value={this.state.users}
                  className="p-datatable-striped"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={10}
                  globalFilter={this.state.globalFilter}
                  rowsPerPageOptions={[10, 20, 50]}
                >
                  <Column field="email" header="Email" sortable></Column>
                  <Column
                    field="role"
                    body={this.roleTemplate}
                    header="Role"
                    sortable
                  ></Column>
                  <Column
                    field="access"
                    body={this.accessTemplate}
                    header="Access"
                    sortable
                  ></Column>
                  <Column
                    field="department"
                    body={this.departmentTemplate}
                    header="Access"
                  ></Column>
                  <Column
                    body={this.dateTemplate}
                    header="Created At"
                    sortable
                  ></Column>
                  <Column body={this.actionTemplate} header="Action"></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
