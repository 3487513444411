import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InputMask } from "primereact/inputmask";
import swal from "sweetalert";
import moment from "moment";
import { authenticationService } from "../../_services";
import { apmaServices } from "../../_services/admin/apma.service";
export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      apmaProfile: {}
    };
  }

  componentDidMount() {
    this.props.id &&
      apmaServices.getApmaProfileById(this.props.id).then((data) => {
        console.log("Data APMA", data);
        this.setState({ apmaProfile: data.Data });
      });
  }

  render() {
    const { id } = this.props;

    return (
      <div className="pt-3">
        <Formik
          enableReinitialize={true}
          initialValues={{
            fullLegalName: this.state.apmaProfile.fullLegalName || "",
            industry: this.state.apmaProfile.industry || "",
            companyAddress: this.state.apmaProfile.companyAddress || "",
            businessWebsite: this.state.apmaProfile.businessWebsite || "",
            contactPerson: this.state.apmaProfile.contactPerson || "",
            position: this.state.apmaProfile.position || "",
            phoneNumber: this.state.apmaProfile.phoneNumber || "",
            mobileNumber: this.state.apmaProfile.mobileNumber || "",
            profileEmail: this.state.apmaProfile.profileEmail || ""
          }}
        >
          {({
            errors,
            status,
            touched,
            isSubmitting,
            setFieldValue,
            values,
            handleChange,
            handleBlur
          }) => {
            return (
              <Form>
                <div className="offset-md-3 col-md-6 text-center">
                  <div className="mb-2">
                    <span>Company Logo</span>
                  </div>
                  <img
                    src={
                      this.state.apmaProfile.companyLogo
                        ? this.state.apmaProfile.companyLogo
                        : "/assets/images/logo-16.svg"
                    }
                    className="img-fluid apma-profile-logo"
                    alt="logo"
                  />
                </div>
                <div className="form-group required">
                  <label className="control-label">Full Legal Name</label>
                  <Field
                    name="fullLegalName"
                    type="text"
                    placeholder="Full Legal Name"
                    disabled
                    className={
                      "form-control" +
                      (errors.fullLegalName && touched.fullLegalName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="fullLegalName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">
                    Company Industry/Sector
                  </label>
                  <Field
                    name="industry"
                    type="text"
                    placeholder="Company Industry/Sector"
                    disabled
                    className={
                      "form-control" +
                      (errors.industry && touched.industry ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="industry"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">Company Address</label>
                  <Field
                    name="companyAddress"
                    type="text"
                    placeholder="Company Address"
                    disabled
                    className={
                      "form-control" +
                      (errors.companyAddress && touched.companyAddress
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="companyAddress"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">Business Website</label>
                  <Field
                    name="businessWebsite"
                    type="text"
                    placeholder="Business Website"
                    disabled
                    className={
                      "form-control" +
                      (errors.businessWebsite && touched.businessWebsite
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="businessWebsite"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">Contact Person</label>
                  <Field
                    name="contactPerson"
                    type="text"
                    placeholder="Business Website"
                    disabled
                    className={
                      "form-control" +
                      (errors.contactPerson && touched.contactPerson
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="contactPerson"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">Position</label>
                  <Field
                    name="position"
                    type="text"
                    placeholder="Position"
                    disabled
                    className={
                      "form-control" +
                      (errors.position && touched.position ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="position"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">Phone Number</label>
                  <InputMask
                    disabled
                    mask="(999) 999-9999"
                    value={values.phoneNumber}
                    className={
                      "form-control" +
                      (errors.phoneNumber && touched.phoneNumber
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => setFieldValue("phoneNumber", e.value)}
                  ></InputMask>
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">Mobile Number</label>
                  <InputMask
                    disabled
                    mask="(999) 999-9999"
                    value={values.mobileNumber}
                    className={
                      "form-control" +
                      (errors.mobileNumber && touched.mobileNumber
                        ? " is-invalid"
                        : "")
                    }
                    onChange={(e) => setFieldValue("mobileNumber", e.value)}
                  ></InputMask>
                  <ErrorMessage
                    name="mobileNumber"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group required">
                  <label className="control-label">Email</label>
                  <Field
                    name="profileEmail"
                    type="text"
                    placeholder="Email"
                    disabled
                    className={
                      "form-control" +
                      (errors.profileEmail && touched.profileEmail
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="profileEmail"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="row">
                  {this.state.apmaProfile.updatedAt ? (
                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                      Latest Update:{" "}
                      {moment(this.state.apmaProfile.updatedAt).format(
                        "MM/DD/YYYY hh:mm a"
                      )}
                    </div>
                  ) : null}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}
