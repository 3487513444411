import config from '../../config';
import { authHeader, handleResponse } from '../../_helpers';
import { encData } from '../../_helpers/_helperFunctions';
export const userService = {
    getAll,
    getUserById,
    changePassword,
    create,
    update,
    deleteUser,
    getUsersByDepartmentId,
    updateCounsellorType
};

function getAll(currentUserId) {
    const requestOptions = { method: 'POST', headers: authHeader(), body: encData({currentUserId: currentUserId})};
    console.log("requestOptions: ", requestOptions)
    return fetch(`${config.apiUrl}/api/v1/users`, requestOptions).then(handleResponse);
}

function getUserById(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/users/${id}`, requestOptions).then(handleResponse);
}

function changePassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: encData(data)
    };
    return fetch(`${config.apiUrl}/api/v1/users/changepassword`, requestOptions).then(handleResponse);
}

function create(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: encData(data)
    };
    return fetch(`${config.apiUrl}/api/v1/users/create`, requestOptions).then(handleResponse);
}

function update(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: encData(data)
    };
    return fetch(`${config.apiUrl}/api/v1/users/create`, requestOptions).then(handleResponse);
}

function deleteUser(userId,currentUserId) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: encData({userId,currentUserId})
    };
    return fetch(`${config.apiUrl}/api/v1/users/delete`, requestOptions).then(handleResponse);
}

function getUsersByDepartmentId(id) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`${config.apiUrl}/api/v1/users/getByDepartmentId/${id}`, requestOptions).then(handleResponse);
}

function updateCounsellorType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: encData(data)
    };
    return fetch(`${config.apiUrl}/api/v1/users/counsellor-type`, requestOptions).then(handleResponse);
}