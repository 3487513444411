import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { sectorService } from "../../_services/admin/sector.service";
import swal from "sweetalert";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";

export default class Sectors extends Component {
  constructor(props) {
    super();
    this.state = {
      currentUser: authenticationService.currentUserValue,
      allSectors: [],
      sectorListGlobalFilter: null,
      sectorLastSearch: ""
    };
  }

  componentDidMount() {
    this.getAllSectors();
    this.setLastSearchedValues();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setLastSearchedValues = () => {
    const { s: sectorName } = queryString.parse(this.props.location.search);

    if (sectorName) {
      this.setState({
        sectorListGlobalFilter: sectorName,
        sectorLastSearch: sectorName
      });
    }
  };

  setFilterOnBack = () => {
    const { s: sectorName } = queryString.parse(this.props.location.search);

    if (sectorName) {
      this.setState({
        sectorListGlobalFilter: sectorName,
        sectorLastSearch: sectorName
      });
    } else {
      this.setState({
        sectorListGlobalFilter: "",
        sectorLastSearch: ""
      });
    }
  };

  getAllSectors = () => {
    sectorService.getAllSectors().then((data) => {
      if (data) {
        this.setState({ allSectors: data.Data });
      }
    });
  };

  deleteSectorById = (sectorId) => {
    let requestData = {
      sectorId,
      currentUserId: this.state.currentUser.id
    };

    sectorService.deleteSectorById(requestData).then((data) => {
      if (data) {
        this.getAllSectors();
        swal("Sector Deleted Successfully", " ", "success");
      }
    });
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container ">
        <span
          className="cursor-pointer text-primary"
          data-toggle="tooltip"
          title="Edit"
        >
          <Link to={`/sectors/edit/${rowData._id}`}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </Link>
        </span>

        <span
          className="cursor-pointer text-danger"
          data-toggle="tooltip"
          title="delete"
          onClick={(e) => this.deleteSectorById(rowData._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </div>
    );
  };

  dateTemplate = (rowData) => {
    return moment(rowData.updatedAt).format("MM/DD/YYYY hh:mm a");
  };

  appendQueryStringOnTextChangeinURL = (sector) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/sectors",
        search: `?s=${sector}`
      });
    } else {
      this.props.history.replace({
        pathname: "/sectors",
        search: `?s=${sector}`
      });
    }
  };

  render() {
    const items = [
      { label: "Management" },
      {
        label: "Sectors"
      }
    ];
    const home = { icon: "pi pi-home", url: "/aboutus" };

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />

        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="text-right mb-2">
                  <Link to="/sectors/add" className="btn btn-primary">
                    Add New Sector
                  </Link>
                </div>
                <div className="mb-3 table-header">
                  <h2>List Of Sectors</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState(
                          {
                            sectorListGlobalFilter: e.target.value,
                            sectorLastSearch: e.target.value
                          },
                          () => {
                            this.appendQueryStringOnTextChangeinURL(
                              this.state.sectorLastSearch
                            );
                          }
                        )
                      }
                      placeholder="Search"
                      value={this.state.sectorLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={this.state.allSectors}
                    className="p-datatable-striped"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={this.state.sectorListGlobalFilter}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="sectorName"
                      header="Sector Name"
                      sortable
                    ></Column>
                    <Column
                      field="updatedAt"
                      header="Modified"
                      sortable
                      body={this.dateTemplate}
                    ></Column>
                    <Column
                      body={this.actionTemplate}
                      header="Action"
                      className="text-center"
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
