import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

export const inviteService = {
  getAllCompaniesToInvite,
  saveInviteHistoryAndSendMail,
  getAllInviteHistory,
  inviteCompanyData,
  getManuallyAddedCompanies,
  deleteInvitedCompany
};

function getAllCompaniesToInvite() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/invite/getAllCompaniesToInvite`,
    requestOptions
  ).then(handleResponse);
}

function saveInviteHistoryAndSendMail(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/invite/saveInviteHistoryAndSendMail`,
    requestOptions
  ).then(handleResponse);
}

function getAllInviteHistory() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/invite/getAllInviteHistory`,
    requestOptions
  ).then(handleResponse);
}

function inviteCompanyData(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };

  return fetch(
    `${config.apiUrl}/api/v1/invite/saveCompanyData`,
    requestOptions
  ).then(handleResponse);
}

function getManuallyAddedCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    body: encData()
  };

  return fetch(
    `${config.apiUrl}/api/v1/invite/getManuallyAddedCompanies`,
    requestOptions
  ).then(handleResponse);
}

function deleteInvitedCompany(inviteCompanyId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    body: encData()
  };

  return fetch(
    `${config.apiUrl}/api/v1/invite/deleteInvitedCompany/${inviteCompanyId}`,
    requestOptions
  ).then(handleResponse);
}
