import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { authenticationService } from "../../_services/authentication.service";
import { Link } from "react-router-dom";
import queryString from "query-string";
export class CompanyPublishList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      unpublishedGlobalFilter: null,
      publishedGlobalFilter: null,
      publishedCompanies: [],
      unpublishedCompanies: [],
      unPublishNameLastSearch: "",
      publishNameLastSearch: ""
    };
  }

  componentDidMount() {
    this.getAllPublishedCompanies();
    this.getAllUnplishedCompanies();
    this.setInitialFilteredValueInSearchBar();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setInitialFilteredValueInSearchBar = () => {
    const { u: unplishedCompanyName, p: publishedCompanyName } =
      queryString.parse(this.props.location.search);
    if (unplishedCompanyName) {
      this.setState({
        unpublishedGlobalFilter: unplishedCompanyName,
        unPublishNameLastSearch: unplishedCompanyName
      });
    }
    if (publishedCompanyName) {
      this.setState({
        publishedGlobalFilter: publishedCompanyName,
        publishNameLastSearch: publishedCompanyName
      });
    }
  };

  setFilterOnBack = () => {
    const { u: unplishedCompanyName, p: publishedCompanyName } =
      queryString.parse(this.props.location.search);

    if (unplishedCompanyName) {
      this.setState({
        unpublishedGlobalFilter: unplishedCompanyName,
        unPublishNameLastSearch: unplishedCompanyName
      });
    } else {
      this.setState({
        unpublishedGlobalFilter: "",
        unPublishNameLastSearch: ""
      });
    }
    if (publishedCompanyName) {
      this.setState({
        publishedGlobalFilter: publishedCompanyName,
        publishNameLastSearch: publishedCompanyName
      });
    } else {
      this.setState({
        publishedGlobalFilter: "",
        publishNameLastSearch: ""
      });
    }
  };

  getAllPublishedCompanies = () => {
    companyService.getAllPublishedCompanies().then((data) => {
      this.setState({ publishedCompanies: data.Data });
    });
  };

  getAllUnplishedCompanies = () => {
    companyService.getAllUnplishedCompanies().then((data) => {
      this.setState({ unpublishedCompanies: data.Data });
    });
  };

  tagCompanyAsPublished = (companyId) => {
    let requestData = {
      companyId,
      currentUserId: this.state.currentUser.id
    };

    companyService.tagCompanyAsPublished(requestData).then((data) => {
      this.getAllUnplishedCompanies();
      this.getAllPublishedCompanies();
    });
  };

  tagCompanyAsUnPublished = (companyId) => {
    let requestData = {
      companyId,
      currentUserId: this.state.currentUser.id
    };

    companyService.tagCompanyAsUnPublished(requestData).then((data) => {
      this.getAllUnplishedCompanies();
      this.getAllPublishedCompanies();
    });
  };

  publishActionTemplate = (rowData) => {
    return (
      <div className="action-container text-success">
        <span className="cursor-pointer">
          <Link to={`/company-details/${rowData._id}`}>
            <FontAwesomeIcon icon={faEye} />
          </Link>
        </span>

        <button
          type="button"
          class="btn btn-success btn-sm"
          onClick={(e) => {
            this.tagCompanyAsPublished(rowData._id);
          }}
        >
          Publish
        </button>
      </div>
    );
  };

  unPublishActionTemplate = (rowData) => {
    return (
      <div className="action-container text-danger">
        <span className="cursor-pointer">
          <Link to={`/company-details/${rowData._id}`}>
            <FontAwesomeIcon icon={faEye} />
          </Link>
        </span>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          onClick={(e) => {
            this.tagCompanyAsUnPublished(rowData._id);
          }}
        >
          Unpublish
        </button>
      </div>
    );
  };

  appendQueryStringOnTextChangeinURL = (unpublishFilter, publishFilter) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/publish-companies",
        search: `?u=${unpublishFilter}&p=${publishFilter}`
      });
    } else {
      this.props.history.replace({
        pathname: "/publish-companies",
        search: `?u=${unpublishFilter}&p=${publishFilter}`
      });
    }
  };

  render() {
    const items = [{ label: "Publish Company" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="mb-3 table-header">
                  <h2>Unpublished Company</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) => {
                        this.setState(
                          {
                            unpublishedGlobalFilter: e.target.value,
                            unPublishNameLastSearch: e.target.value
                          },
                          () => {
                            this.appendQueryStringOnTextChangeinURL(
                              this.state.unpublishedGlobalFilter,
                              this.state.publishNameLastSearch
                            );
                          }
                        );
                      }}
                      placeholder="Search"
                      value={this.state.unPublishNameLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={this.state.unpublishedCompanies}
                    className="p-datatable-striped"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={this.state.unpublishedGlobalFilter}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    {/* <Column
                      field="industry"
                      header="Industry"
                      sortable
                    ></Column> */}
                    <Column field="email" header="Email" sortable></Column>
                    <Column
                      body={this.publishActionTemplate}
                      header="Action"
                      className="text-center"
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="mb-3 table-header">
                  <h2>Published Company</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) => {
                        this.setState(
                          {
                            publishedGlobalFilter: e.target.value,
                            publishNameLastSearch: e.target.value
                          },
                          (e) => {
                            this.appendQueryStringOnTextChangeinURL(
                              this?.state?.unPublishNameLastSearch,
                              this.state.publishedGlobalFilter
                            );
                          }
                        );
                      }}
                      placeholder="Search"
                      value={this.state.publishNameLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={this.state.publishedCompanies}
                    className="p-datatable-striped"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={this.state.publishedGlobalFilter}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    {/* <Column
                      field="industry"
                      header="Industry"
                      sortable
                    ></Column> */}
                    <Column field="email" header="Email" sortable></Column>
                    <Column
                      body={this.unPublishActionTemplate}
                      header="Action"
                      className="text-center"
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyPublishList;
