import EncDec from "../e2e/e2e_functions/index";
import moment from "moment";
import { AUDIO_FILES, VIDEO_FILES, IMAGE_TYPES, DOCS_FILES } from "./common";

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const isEmpty = (value) => {
  return (
    value == null || // From standard.js: Always use === - but obj == null is allowed to check null || undefined
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const encData = function (data) {
  console.log("encData::", data);
  let isenc = localStorage.getItem("isenc")
    ? parseInt(localStorage.getItem("isenc"))
    : 0;
  if (isenc === 1) {
    return JSON.stringify({
      enc_string: EncDec.encryptResponse(JSON.stringify(data))
    });
  } else {
    return JSON.stringify(data);
  }
};

export const capitalizeAndJoin = (data) => {
  let capitalizedWordsList = data.map((elem) => {
    return capitalize(elem);
  });

  return capitalizedWordsList.join(", ");
};

export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

export const dateTemplate = (date) => moment(date).format("MM/DD/YYYY hh:mm a");

export const getFieldValue = function (obj, path, def) {
  for (var i = 0, path = path.split("."), len = path.length; i < len; i++) {
    if (!obj[path[i]]) return def;
    obj = obj[path[i]];
  }
  return obj;
};

export const isObjectEmpty = (givenObject) => {
  if (givenObject) {
    let keys = Object.keys(givenObject);
    return keys.length ? false : true;
  }
  return true;
};

export const concateAddress = (details = {}) => {
  let addressString = "";

  if (details.address) {
    addressString += `${details.address}, `;
  }

  if (details.province) {
    addressString += `${details.province}, `;
  }

  if (details.country) {
    addressString += `${details.country}, `;
  }

  if (details.postalCode) {
    addressString += `${details.postalCode}`;
  }

  return addressString;
};

export const getClickableLink = (link) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `http://${link}`;
};

export const getFileType = function (fileExtension) {
  if (AUDIO_FILES.includes(fileExtension)) {
    return "audio";
  } else if (VIDEO_FILES.includes(fileExtension)) {
    return "video";
  } else if (IMAGE_TYPES.includes(fileExtension)) {
    return "image";
  } else if (DOCS_FILES.includes(fileExtension)) {
    return "docs";
  } else {
    return "others";
  }
};

export const getFileExtension = (filename) => {
  return filename.split(".").pop();
};

export const formatAddressForGoogle = function (address) {
  return address.toString().replace(/\s+/g, "+");
};

export const getTenYearBeforeAndAfterCurrentYear = () => {
  let list = [];
  let currentMoment = moment().add(10, "years");
  list.push({
    label: currentMoment.format("YYYY"),
    value: currentMoment.format("YYYY")
  });
  for (let i = 0; i < 15; i++) {
    let yearString = currentMoment.subtract(1, "years").format("YYYY");
    list.push({
      label: yearString,
      value: yearString
    });
  }
  return list;
};

export const isFormCompleted = (formData = {}, noOfFields, name) => {
  if (name === "aboutResources") {
    let mutatedObject = { ...formData };
    console.log("mutatedObject: ", mutatedObject);
    for (const [key, value] of Object.entries(mutatedObject)) {
      if (
        key.endsWith("Text") &&
        key !== "employeeAtCurrentLocationText" &&
        key !== "revenueInText"
      ) {
        delete mutatedObject[key];
      }
    }
    formData = { ...mutatedObject };
  }
  let keyList = Object.keys(formData);
  let flag = true;
  if (keyList.length < noOfFields) {
    return false;
  }
  keyList.forEach((key) => {
    if (Array.isArray(formData[key])) {
      if (!formData[key].length) {
        flag = false;
      }
    } else {
      if (!formData[key]) {
        flag = false;
      }
    }
  });
  return flag;
};
export const removeAllDotsButLast = (str) => {
  return str.replace(/[.](?=.*[.])/g, "-");
};

export const highLightInProfileChangeRequest = (
  oldValue = "",
  newValue = ""
) => {
  console.log("oldValue: ", oldValue);
  console.log("newValue: ", newValue);
  if (newValue === null) {
    newValue = [];
  }
  if (oldValue === null) {
    oldValue = [];
  }
  if (typeof oldValue === "string") {
    let value = oldValue.localeCompare(newValue);
    return value;
  }
  if (typeof oldValue === "object") {
    let oldValueParsed = oldValue.join();
    let newValueParsed = newValue.join();
    let value = oldValueParsed.localeCompare(newValueParsed);
    return value;
  }

  if (typeof oldValue === "boolean") {
    let oldValueParsed = oldValue.toString();
    let newValueParsed = newValue.toString();
    let value = oldValueParsed.localeCompare(newValueParsed);
    return value;
  }
};

export const countProfileCompletionStateByField = (formData = {}) => {
  let fieldWithValue = 0;

  for (const [key, value] of Object.entries(formData)) {
    if (Array.isArray(formData[key]) && formData[key].length > 0) {
      fieldWithValue = fieldWithValue + 1;
    } else if (
      formData[key] !== null &&
      formData[key].toString() &&
      formData[key].length > 0 &&
      key !== "materialsCapabilitesText" &&
      key !== "businessLeadershipText" &&
      key !== "industriesSuppliedText"
    ) {
      fieldWithValue = fieldWithValue + 1;
    }
  }

  return fieldWithValue;
};
