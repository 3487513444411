import React, { Component } from "react";
import swal from "sweetalert";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { serviceKeywordService } from "../../_services/admin/servicesKeywords.service";
export default class AddEditServiceKeyword extends Component {
  constructor(props) {
    super();
    this.state = {
      currentUser: authenticationService.currentUserValue,
      serviceKeywordData: {}
    };
  }

  componentDidMount() {
    this.getServiceKeywordById();
  }

  getServiceKeywordById = () => {
    let serviceKeywordId = this.props.match.params.serviceKeywordId;

    serviceKeywordId &&
      serviceKeywordService
        .getServiceKeywordById(serviceKeywordId)
        .then((data) => {
          this.setState({ serviceKeywordData: data.Data });
        });
  };

  render() {
    const items = [
      { label: "Management" },
      {
        label: "Add/Edit Service Keyword"
      }
    ];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <div className="col-md-6">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    serviceKeyword:
                      this.state.serviceKeywordData.serviceKeyword || "",
                    serviceKeywordId: this.state.serviceKeywordData._id || "",
                    currentUserId: this.state.currentUser.id
                  }}
                  validationSchema={Yup.object().shape({
                    serviceKeyword: Yup.string().required(
                      "Service/Keyword is Required"
                    )
                  })}
                  onSubmit={(formData, { setStatus, setSubmitting }) => {
                    setSubmitting(true);
                    serviceKeywordService
                      .AddAndUpdateServices(formData)
                      .then((data) => {
                        if (data) {
                          setSubmitting(false);
                          swal(
                            "Service Keyword Saved Successfully",
                            " ",
                            "success"
                          );
                          const { from } = this.props.location.state || {
                            from: { pathname: "/servicesKeyword" }
                          };
                          this.props.history.push(from);
                        }
                      });
                  }}
                >
                  {({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    values,
                    handleChange,
                    handleBlur
                  }) => {
                    return (
                      <Form>
                        <div className="form-group required">
                          <label className="control-label">
                            {this.state.serviceKeywordData._id
                              ? "Update Service/Keyword"
                              : "Add Service/Keyword"}
                          </label>
                          <Field
                            name="serviceKeyword"
                            type="text"
                            placeholder="Service Keyword"
                            className={
                              "form-control" +
                              (errors.serviceKeyword && touched.serviceKeyword
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="serviceKeyword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group text-center mb-0 m-t-20">
                          <div className="offset-md-3 col-md-6 col-xs-12">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-lg btn-block text-uppercase "
                              style={{ width: "115%" }}
                            >
                              {this.state.serviceKeywordData._id
                                ? "Update Service"
                                : "Add Service"}
                              {isSubmitting && (
                                <span>
                                  {" "}
                                  <i className="pi pi-spin pi-spinner"></i>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
