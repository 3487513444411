import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { inviteService } from "../../_services/admin/invite.service";
import { authenticationService } from "../../_services";
import moment from "moment";
import swal from "sweetalert";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { weedcLink } from "../../config";
import { Link } from "react-router-dom";
import { Progress } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { removeAllDotsButLast } from "../../_helpers/_helperFunctions";
import slugify from "slugify";
import config from "../../config";

export default class Invite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      companies: [],
      selectedBadges: [],
      selectedCompanyId: null,
      globalFilter: null,
      companyListModal: true,
      selectedCompanies: [],
      histories: [],
      companyGlobalFilter: null,
      fileUploadModal: false,
      attachmentsFile: [],
      uploadProgress: 0,
      emailHistoryModal: false,
      selectedEmailHistory: {},
    };
  }

  componentDidMount() {
    this.getAllCompaniesToInvite();
    this.getAllInviteHistory();
  }

  getAllInviteHistory = () => {
    inviteService.getAllInviteHistory().then((data) => {
      if (data && data.Status) {
        console.log("data: ", data);
        this.setState({ histories: data.Data });
      }
    });
  };

  getAllCompaniesToInvite = () => {
    inviteService.getAllCompaniesToInvite().then((data) => {
      if (data && data.Status) {
        this.setState({ companies: data.Data });
      }
    });
  };

  actionTemplate = (rowData) => {
    if (rowData.sendAttachment) {
      return (
        <div>
          {rowData.attachment1} <br /> {rowData.attachment2}
        </div>
      );
    }
    return "";
  };

  assignBadges = () => {
    let requestData = {
      companyId: this.state.selectedCompanyId,
      currentUserId: this.state.currentUser.id,
      badges: this.state.selectedBadges.map((badge) => badge.value),
    };
    companyService.assignBadges(requestData).then((data) => {
      if (data && data.Status) {
        swal("Asssigned badges succesfully", "", "success");
        this.closeAssignBadgeModal();
        this.getAllActiveCompanies();
      }
    });
  };

  openCompanyListModal = () => {
    this.setState({
      companyListModal: true,
    });
  };

  openFileUploadModal = () => {
    this.setState({ fileUploadModal: true });
  };

  closeFileUploadModal = () => {
    this.setState({ fileUploadModal: false, uploadProgress: 0 });
  };

  closeCompanyListModal = () => {
    this.setState({ companyListModal: false });
  };

  dateTemplate = (rowData) => {
    return moment(rowData.updatedAt || rowData.createdAt).format(
      "MM/DD/YYYY hh:mm a"
    );
  };

  handleOnChange = (value) => {
    console.log("handleOnChange value: ", value);

    this.setState({ selectedCompanies: value });
  };

  companiesCount = (rowData) => {
    return rowData.companiesNames ? rowData.companiesNames.length : "";
  };

  companiesName = (rowData) => {
    return rowData.companiesNames ? rowData.companiesNames.join(", ") : "";
  };

  getCountText = (count) => {
    if (count === 1) {
      return `${count} company selected`;
    }
    return `${count} companies selected`;
  };

  removeFileFromAttachment = (file) => {
    const updatedAttachedFileArray = this.state.attachmentsFile.filter(
      (files) => files.url !== file.url
    );

    this.setState({ attachmentsFile: updatedAttachedFileArray }, () => {
      console.log(this.state.attachmentsFile);
    });
  };

  emailHistoryTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.openEmailHistoryModel(rowData)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
      </div>
    );
  };

  openEmailHistoryModel = (emailHistory) => {
    console.log(emailHistory);
    this.setState({
      emailHistoryModal: true,
      selectedEmailHistory: emailHistory,
    });
  };

  closeEmailHistoryModel = () => {
    this.setState({ emailHistoryModal: false, selectedEmailHistory: {} });
  };

  renderHtmlContent = (htmlText) => {
    return { __html: htmlText };
  };

  render() {
    const items = [{ label: "Invites" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    const { attachmentsFile, emailHistory, selectedEmailHistory } = this.state;

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <div className="row">
                <div className="col-md-6">
                  <Formik
                    initialValues={{
                      subject: "",
                      body: "",
                      sendAttachment: false,
                      attachment1: "",
                      attachment2: "",
                    }}
                    validationSchema={Yup.object().shape({
                      subject: Yup.string().required("Subject is required"),
                      body: Yup.string().required("Body is required"),
                    })}
                    onSubmit={(
                      formData,
                      { setStatus, setSubmitting, resetForm }
                    ) => {
                      if (this.state.selectedCompanies.length === 0) {
                        setSubmitting(false);
                        swal("Please select the companies first", "", "error");
                        return;
                      }
                      console.log("form-data: ", formData);
                      let requestData = {
                        ...formData,
                        companies: this.state.selectedCompanies.map(
                          (company) => company._id
                        ),
                        companiesNames: this.state.selectedCompanies.map(
                          (company) => company.companyTitle
                        ),
                        currentUserId: this.state.currentUser.id,
                        userName: `${this.state.currentUser.firstName} ${this.state.currentUser.lastName}`,
                        attachmentsFile: attachmentsFile,
                      };
                      console.log("requestData: ", requestData);
                      setSubmitting(true);
                      inviteService
                        .saveInviteHistoryAndSendMail(requestData)
                        .then((data) => {
                          if (data && data.Status) {
                            setSubmitting(false);
                            this.getAllInviteHistory();
                            swal("Sent mail", "", "success");
                            resetForm();
                            this.setState({
                              selectedCompanies: [],
                              attachmentsFile: [],
                            });
                          }
                        });
                    }}
                  >
                    {({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      values,
                      handleChange,
                      handleBlur,
                    }) => {
                      return (
                        <Form>
                          <div className="form-group required">
                            <label className="control-label">Subject</label>
                            <Field
                              name="subject"
                              type="text"
                              placeholder="Subject"
                              className={
                                "form-control" +
                                (errors.subject && touched.subject
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="subject"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group required">
                            <label className="control-label">Body</label>
                            <Editor
                              style={{
                                height: "120px",
                              }}
                              value={values.body}
                              onTextChange={(e) =>
                                setFieldValue("body", e.htmlValue)
                              }
                            />
                            <ErrorMessage
                              name="body"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          {/* {attachmentsFile.length > 0 && (
                            <>
                              <label className="control-label">
                                Attached Files
                              </label>
                            </>
                          )} */}
                          {attachmentsFile.length > 0 &&
                            attachmentsFile.map((file, index) => {
                              return (
                                <>
                                  <div
                                    className="attachment-button-padding"
                                    key={index}
                                  >
                                    <button
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={file.originalname}
                                      className="attachment-button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        console.log(file);
                                        this.removeFileFromAttachment(file);
                                      }}
                                    >
                                      <span>
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faPaperclip}
                                        />{" "}
                                        {file.originalname}
                                      </span>
                                    </button>
                                  </div>
                                </>
                              );
                            })}

                          <div className="form-check">
                            <Field
                              name="sendAttachment"
                              type="checkbox"
                              id="sendAttachment"
                              className=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="sendAttachment"
                            >
                              &nbsp; Send Attachment
                            </label>
                          </div>

                          {values.sendAttachment && (
                            <>
                              {/* <div className="form-group required">
                                <label className="control-label">
                                  Attachment 1
                                </label>
                                <select
                                  name="attachment1"
                                  value={values.attachment1}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="form-control"
                                >
                                  <option value="" label="Select a Survey" />
                                  <option value={weedcLink} label={weedcLink} />
                                </select>
                                <ErrorMessage
                                  name="attachment1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div> */}
                              <div className="text-left">
                                <button
                                  className="btn btn-success"
                                  type="button"
                                  onClick={this.openFileUploadModal}
                                >
                                  Attach Files{" "}
                                  <FontAwesomeIcon icon={faPaperclip} />
                                </button>
                              </div>
                            </>
                          )}

                          <div className="form-group text-center mb-0 m-t-20">
                            <div className="offset-md-3 col-md-6 col-xs-12">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary btn-lg btn-block text-uppercase"
                              >
                                Send
                                {isSubmitting && (
                                  <span>
                                    {" "}
                                    <i className="pi pi-spin pi-spinner"></i>
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>

                          {/* <div className="mt-2">
                            <label className="custom-logo-upload">
                              <input
                                type="file"
                                name="uploadFile"
                                onChange={(e) => {
                                  //event.target.files;
                                  console.log("e: ", e.target.value);
                                  console.log(e.target.uploadFile);
                                  // dataDispatch({ type: 'photoLoader', payload: true })

                                  //this.changeProfilepic(e);
                                }}
                              />
                              Change Logo
                            </label>
                          </div> */}

                          {status && (
                            <div className={"alert alert-danger mt-2"}>
                              {status}
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>

                <Dialog
                  header="Attach files "
                  visible={this.state.fileUploadModal}
                  style={{ width: "40vw" }}
                  onHide={this.closeFileUploadModal}
                >
                  <Formik
                    initialValues={{
                      title: "",
                      description: "",
                      file: null,
                    }}
                    validationSchema={Yup.object().shape({})}
                    onSubmit={(formData, { setStatus, setSubmitting }) => {
                      setSubmitting(true);
                      let originalname = formData.file.name.toLowerCase();
                      let fileName =
                        "email-attachments/" +
                        removeAllDotsButLast(
                          slugify(originalname, { lower: true })
                        );
                      let fileType = formData.file.type;
                      axios
                        .post(
                          `${config.apiUrl}/api/v1/companies/files/upload`,
                          {
                            fileName: fileName,
                            fileType: fileType,
                          },
                          {
                            headers: {
                              Authorization: `Bearer ${this.state.currentUser.token}`,
                              isenc: localStorage.getItem("isenc")
                                ? parseInt(localStorage.getItem("isenc"))
                                : 0,
                            },
                          }
                        )
                        .then((response) => {
                          console.log(response.data);
                          var returnData = response.data.Data;
                          var signedRequest = returnData.signedRequest;
                          var url = returnData.url;
                          console.log("------> url: ", url);
                          //this.setState({ url: url });
                          console.log(
                            "Recieved a signed request " + signedRequest
                          );

                          // Put the fileType in the headers for the upload
                          var options = {
                            headers: {
                              "Content-Type": fileType,
                            },
                            onUploadProgress: (ev) => {
                              const progress = parseInt(
                                (ev.loaded / ev.total) * 100
                              );
                              this.setState({
                                uploadProgress: progress,
                              });
                            },
                          };
                          axios
                            .put(signedRequest, formData.file, options)
                            .then((result) => {
                              console.log("Response from s3", result);
                              let requestData = {
                                fileName: fileName,
                                originalname: originalname,
                                url: url,
                                companyId: this.state.currentUser.id,
                                currentUserId: this.state.currentUser.id,
                                title: formData.title,
                                description: formData.description,
                                fileType: fileType,
                              };
                              this.setState();
                              swal("File uploaded successfully", "", "success");

                              console.log("requestData: ", requestData);
                              const newArrayWithUpdateURL = Array.from(
                                attachmentsFile
                              );

                              newArrayWithUpdateURL.push({
                                url: requestData.url,
                                originalname,
                              });
                              this.setState(
                                {
                                  attachmentsFile: newArrayWithUpdateURL,
                                },
                                () => {
                                  console.log(attachmentsFile);
                                }
                              );
                              this.closeFileUploadModal();
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    {({
                      errors,
                      status,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      values,
                      handleChange,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col">
                              <Progress
                                style={{
                                  height: "17px",
                                  backgroundColor: "#5955a5",
                                }}
                                className="mb-2"
                                value={this.state.uploadProgress}
                              >
                                <strong>{this.state.uploadProgress} %</strong>
                              </Progress>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col form-group required">
                              <label htmlFor="file">File</label>
                              <input
                                id="file"
                                name="file"
                                type="file"
                                onChange={(event) => {
                                  setFieldValue(
                                    "file",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                className="form-control"
                              />
                              <ErrorMessage
                                name="file"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>

                          <div className="form-group text-center mb-0 m-t-20">
                            <div className="offset-md-3 col-md-6 col-xs-12">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary btn-lg btn-block text-uppercase"
                              >
                                Save
                                {isSubmitting && (
                                  <span>
                                    {" "}
                                    <i className="pi pi-spin pi-spinner"></i>
                                  </span>
                                )}
                              </button>
                            </div>
                          </div>

                          {status && (
                            <div className={"alert alert-danger mt-2"}>
                              {status}
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </Dialog>

                <div className="col-md-6">
                  <div className="text-right">
                    <button
                      className="btn btn-success btn-lg"
                      type="button"
                      onClick={this.openCompanyListModal}
                    >
                      Company List
                    </button>
                  </div>

                  <div className="text-right">
                    <Link
                      to="/invite-company-data"
                      className="btn btn-primary btn-sm mt-2"
                    >
                      Add Company Data
                    </Link>
                  </div>

                  {this.state.selectedCompanies.length ? (
                    <div className="alert alert-success my-3" role="alert">
                      {this.getCountText(this.state.selectedCompanies.length)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <div className="card">
              <div className="datatable-filter">
                {this.state.histories.length ? (
                  <div className="card">
                    <div className="mb-3 table-header">
                      <h2>History</h2>
                      <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                          type="search"
                          onInput={(e) =>
                            this.setState({
                              globalFilter: e.target.value,
                            })
                          }
                          placeholder="Search"
                        />
                      </span>
                    </div>
                    <div className="custom-table-responsive ">
                      <DataTable
                        value={this.state.histories}
                        className="p-datatable-striped"
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={10}
                        globalFilter={this.state.globalFilter}
                        rowsPerPageOptions={[10, 20, 50]}
                      >
                        <Column
                          field="userName"
                          header="From"
                          sortable
                        ></Column>
                        <Column
                          field="companiesName"
                          header="Total Company Count"
                          body={this.companiesCount}
                          sortable
                        ></Column>
                        <Column
                          field="createdAt"
                          header="Time"
                          body={this.dateTemplate}
                          sortable
                        ></Column>
                        {/* <Column
                          body={this.actionTemplate}
                          header="Action"
                        ></Column> */}
                        <Column
                          field="companiesName"
                          header="Company List"
                          body={this.companiesName}
                          sortable
                        ></Column>
                        <Column
                          header="Action"
                          body={this.emailHistoryTemplate}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <h3 className="text-center">No Histories Available</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Published Company List"
          className="Company-List-model"
          visible={this.state.companyListModal}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          onHide={this.closeCompanyListModal}
          contentClassName="assign-badge-modal-content"
          maximizable={true}
          modal={true}
        >
          <div className="mb-3 table-header">
            <h2>Company list</h2>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                onInput={(e) =>
                  this.setState({
                    companyGlobalFilter: e.target.value,
                  })
                }
                placeholder="Search"
              />
            </span>
          </div>
          <div className="custom-table-responsive ">
            <DataTable
              scrollable
              scrollHeight="580px"
              value={this.state.companies}
              selection={this.state.selectedCompanies}
              onSelectionChange={(e) => this.handleOnChange(e.value)}
              dataKey="_id"
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={10}
              globalFilter={this.state.companyGlobalFilter}
              rowsPerPageOptions={[10, 20, 50]}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3em" }}
              ></Column>
              <Column field="companyTitle" header="Company Name"></Column>
              {/* <Column field="industry" header="Industry/Sector"></Column> */}
            </DataTable>
          </div>
          {this.state.selectedCompanies.length ? (
            <div className="alert alert-success" role="alert">
              {this.getCountText(this.state.selectedCompanies.length)}
            </div>
          ) : null}

          <button
            type="button"
            disabled={!this.state.selectedCompanies.length}
            className="btn btn-primary float-right"
            onClick={() => {
              this.closeCompanyListModal();
            }}
          >
            Ok
          </button>
        </Dialog>

        <Dialog
          header="Email History Details"
          visible={this.state.emailHistoryModal}
          style={{ width: "40vw" }}
          onHide={this.closeEmailHistoryModel}
        >
          <div className="company-info-details">
            <div className="company-info-box p-2">
              <h4 className="py-2">Email Subject</h4>
              <p>{selectedEmailHistory.subject}</p>
            </div>
            <div className="company-info-box p-2">
              <h4 className="py-2">Email Body</h4>
              <p
                dangerouslySetInnerHTML={this.renderHtmlContent(
                  selectedEmailHistory.body
                )}
              ></p>
            </div>

            {/* <div className="company-info-box p-2">
              <h4 className="py-2">Email Attachments</h4>

              {selectedEmailHistory?.attachmentsFile.length &&
                selectedEmailHistory?.attachmentsFile.map((attachments) => {
                  return <p>{attachments}</p>;
                })}
            </div> */}
          </div>
        </Dialog>
      </>
    );
  }
}
