export const sectorOptions = [
  { value: "Accommodation ", label: "Accommodation " },
  { value: "Food Services ", label: "Food Services " },
  {
    value: "Administrative and Support ",
    label: "Administrative and Support "
  },
  { value: "Waste Management", label: "Waste Management" },
  { value: "Remediation Services", label: "Remediation Services" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Forestry ", label: "Forestry " },
  { value: "Fishing and Hunting ", label: "Fishing and Hunting " },
  { value: "Arts", label: "Arts" },
  {
    value: "Entertainment and Recreation ",
    label: "Entertainment and Recreation "
  },
  { value: "Construction", label: "Construction" },
  { value: "Educational Services", label: "Educational Services" },
  { value: "Health Care ", label: "Health Care " },
  { value: "Social Assistance", label: "Social Assistance" },
  {
    value: "Information and Cultural Industries",
    label: "Information and Cultural Industries"
  },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Mining ", label: "Mining " },
  { value: "Oil and Gas Extraction", label: "Oil and Gas Extraction" },
  { value: "Other Services", label: "Other Services" },
  { value: "Professional Services", label: "Professional Services" },
  {
    value: "Scientific and Technical Services",
    label: "Scientific and Technical Services"
  },
  { value: "Retail Trade", label: "Retail Trade" },
  { value: "Public Administration", label: "Public Administration" },
  { value: "Transportation", label: "Transportation" },
  { value: "Warehousing", label: "Warehousing" },
  { value: "Wholesale Trade", label: "Wholesale Trade" }
];

export const getSectorPreselectedOptions = function (sectorList) {
  if (sectorList) {
    return sectorList.map((sector) => {
      return { value: sector, label: sector };
    });
  }
  return [];
};

export const IMAGE_TYPES = ["jpg", "jpeg", "png", "svg", "gif", "webp"];
export const AUDIO_FILES = [
  "mp3",
  "wav",
  "ogg",
  "aac",
  "flac",
  "alac",
  "dsd",
  "aiff",
  "m4a",
  "wma"
];
export const VIDEO_FILES = [
  "mp4",
  "wmv",
  "ogv",
  "avi",
  "flv",
  "3gp",
  "mov",
  "webm",
  "3g2",
  "mpg",
  "mkv",
  "m4v",
  "m4a",
  "f4v",
  "f4a",
  "m4b",
  "m4r",
  "f4b",
  "mpeg"
];

export const DOCS_AND_IMAGES = [
  "png",
  "jpg",
  "jpeg",
  "webp",
  "svg",
 
];

export const DOCS_FILES = ["csv","xls", "xlsx", "docx" , "pptx" ,"doc" ,"odp", "ppt"];
export const SECTIONS = {
  demographics: "Company Overview",
  contactDetails: "Contact Details",
  aboutResources: "Details and Capabilites",
  aboutBusinesses: "About Businesses",
  aboutOpportunities: "About Opportunities"
};

export const COMPANYPROFILEFIELDS = {
  companyTitle: "Company Title",
  sector: "Sector",
  parentName: "Parent Name",
  description: "Description",
  address: "Address",
  address1: "Address 1",
  municipality: "Municipality",
  city: "City",
  province: "Province",
  country: "Country",
  postalCode: "Postal Code",
  headQuarterOrBranch: "Headquarters or Branch",
  HQCountry: "Headquarters – Country",
  HQCity: "Headquarters – City",
  phoneNumber: "Phone Number",
  fax: "Fax number",
  email: "Email",
  website: "Website",
  facebook: "Facebook",
  twitter: "Twitter",
  linkedin: "Linkedin",
  instagram: "Instagram",
  youtubeChannelPath: "YouTube channel path",
  executiveName: "Executive contact name",
  executiveTitle: "Executive contact title",
  executiveEmail: "Executive contact email",
  executiveTelephone: "Executive contact telephone",
  salesName: "Sales contact name",
  salesTitle: "Sales contact title",
  salesEmail: "Sales contact email",
  salesTelephone: "Sales contact telephone",
  employeesAtCurrentLocation: "Employees at this location",
  employeeAtCurrentLocationText: "Employees at this location",
  industriesSupplied: "Industries supplied",
  primary2Digit: "Primary NAICS 2-digit",
  primary3Digit: "Primary NAICS 3-digit",
  primary4Digit: "Primary NAICS 4-digit",
  primary5Digit: "Primary NAICS 5-digit",
  primary6Digit: "Primary NAICS 6-digit",
  secondary2Digit: "Secondary NAICS 2-digit",
  secondary3Digit: "Secondary NAICS 3-digit",
  secondary4Digit: "Secondary NAICS 4-digit",
  secondary5Digit: "Secondary NAICS 5-digit",
  secondary6Digit: "Secondary NAICS 6-digit",
  certification: "Certification",
  certificationText: "Certification Text",
  businessLeadership: "Business Leadership",
  businessLeadershipText: "Business Leadership Text",
  disasterRecovery: "Able to support in disaster recovery",
  governmentSupplier: "Has been government supplier?",
  exportMarkets: "Export markets",
  industriesAffliation: "Industry associations",
  revenue: "Revenue",
  revenueInText: "Revenue",
  facilitySize: "Facility size (Square feet)",
  establishmentYear: "Year of establishment",
  isActive: "Active/InActive",
  materialCapabilites: "Material capabilities",
  userName: "User Name",
  userEmail: "User Email",
  isHiring: "Is Hiring?",
  hiringLink: "Hiring Link"
};

export const getOptionForSingleSelect = (sectorData) => {
  if (sectorData !== null && sectorData.length > 0) {
    return { value: sectorData[0], label: sectorData[0] };
  }
  return null;
};
