import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { departmentService } from "../../_services/superadmin/department.service";
import Preloader from "../../components/Preloader";
import moment from "moment";
import swal from "sweetalert";

export default class Departments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      departments: [],
      globalFilter: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.getAllDepartments();
  }

  getAllDepartments() {
    departmentService.getAll().then((data) => {
      if (data && data.Data) {
        this.setState({ departments: data.Data, loading: false });
      }
    });
  }

  delete(departmentId) {
    swal({
      title: "Are you sure?",
      text: "You want to delete this Department",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        departmentService
          .deleteDepartment(departmentId, this.state.currentUser.id)
          .then((response) => {
            response.Status && this.getAllDepartments();
            swal("Department Deleted Succesfully!", "", "success");
          })
          .catch((err) => {
            console.log(err);
            swal("Oops Something Went Wrong!", "", "error");
          });
      }
    });
  }

  dateTemplate = (rowData) => {
    return moment(rowData.createdAt).format("MM/DD/YYYY hh:mm a");
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <Link to={`/super-admin/department/edit/${rowData._id}`}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </Link>

        <span
          className="cursor-pointer text-danger"
          onClick={(e) => this.delete(rowData._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </div>
    );
  };

  render() {
    const { loading } = this.state;
    const items = [{ label: "Departments" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        {loading && <Preloader />}
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="text-right mb-2">
                  <Link
                    to="/super-admin/department/add"
                    className="btn btn-primary"
                  >
                    Add Department
                  </Link>
                </div>
                <div className="mb-3 table-header">
                  <h2>Departments</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState({ globalFilter: e.target.value })
                      }
                      placeholder="Search"
                    />
                  </span>
                </div>
                <DataTable
                  value={this.state.departments}
                  className="p-datatable-striped"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={10}
                  globalFilter={this.state.globalFilter}
                  rowsPerPageOptions={[10, 20, 50]}
                >
                  <Column field="name" header="Department" sortable></Column>
                  <Column
                    body={this.dateTemplate}
                    header="Created At"
                    sortable
                  ></Column>
                  <Column body={this.actionTemplate} header="Action"></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
