import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

export const highlightService = {
  addHighlight,
  approveHighlight,
  rejectHighlight,
  getPendingHighlights,
  getApprovedHighlights,
  getAllUnpublishedHighlights,
  publisheAndUnPublishCompany,
  holdUnHoldHighlight,
  getAllHoldCompanies,
  deleteHighlight
};

function addHighlight(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(`${config.apiUrl}/api/v1/highlights`, requestOptions).then(
    handleResponse
  );
}

function approveHighlight(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/highlights/approve`,
    requestOptions
  ).then(handleResponse);
}

function rejectHighlight(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/highlights/reject`,
    requestOptions
  ).then(handleResponse);
}

function holdUnHoldHighlight(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(`${config.apiUrl}/api/v1/highlights/hold`, requestOptions).then(
    handleResponse
  );
}

function getPendingHighlights() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/api/v1/highlights`, requestOptions).then(
    handleResponse
  );
}

function getApprovedHighlights() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/highlights?type=approved`,
    requestOptions
  ).then(handleResponse);
}

function getAllUnpublishedHighlights() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/highlights/get-all-unpublished-highlights`,
    requestOptions
  ).then(handleResponse);
}

function getAllHoldCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/highlights/get-all-hold-highlights`,
    requestOptions
  ).then(handleResponse);
}

function publisheAndUnPublishCompany(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/highlights/publish-unpublish-highlights`,
    requestOptions
  ).then(handleResponse);
}

function deleteHighlight(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/highlights/delete-highlight`,
    requestOptions
  ).then(handleResponse);
}
