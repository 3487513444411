import config, { apiUrl } from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";
export const serviceKeywordService = {
  getAllServiceKeyword,
  deleteServiceKeywordById,
  AddAndUpdateServices,
  getServiceKeywordById
};

function getAllServiceKeyword() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/servicesKeyword/getAllServiceKeyword`,
    requestOptions
  ).then(handleResponse);
}

function deleteServiceKeywordById(requestData) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(requestData)
  };

  return fetch(
    `${config.apiUrl}/api/v1/servicesKeyword/deletedServiceKeywordById`,
    requestOptions
  ).then(handleResponse);
}

function AddAndUpdateServices(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/servicesKeyword/addAndUpdateServiceKeyword`,
    requestOptions
  ).then(handleResponse);
}

function getServiceKeywordById(serviceKeywordId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/servicesKeyword/getServiceKeywordById/${serviceKeywordId}`,
    requestOptions
  ).then(handleResponse);
}
