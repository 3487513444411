export const productCategories = [
  {
    key: "Body",
    label: "Body",
    data: "Documents Folder",
    selectable: false,
    children: [
      {
        key: "Body > Closures",
        label: "Closures",
        data: "Closures",
        selectable: false,
        children: [
          {
            key: "Body > Closures > Doors",
            label: "Doors",
            data: "Doors"
          },
          {
            key: "Body > Closures > Hood",
            label: "Hood",
            data: "Hood"
          }
        ]
      },
      {
        key: "Body > Frame",
        label: "Frame",
        data: "Frame",
        selectable: false,
        children: [
          {
            key: "Body > Frame > Body in White (With Paint)",
            label: "Body in White (With Paint)",
            data: "Invoices for this month",
            selectable: false,
            children: [
              {
                key:
                  "Body > Frame > Body in White (With Paint) > Rolling Chassis",
                label: "Rolling Chassis",
                data: "Rolling Chassis",
                selectable: false,
                children: [
                  {
                    key:
                      "Body > Frame > Body in White (With Paint) > Rolling Chassis > Front Unit - Rail LS/RS, Reinforcement UL/UR, Front Body Panel, Scuttle",
                    label:
                      "Front Unit - Rail LS/RS, Reinforcement UL/UR, Front Body Panel, Scuttle",
                    data:
                      "Front Unit - Rail LS/RS, Reinforcement UL/UR, Front Body Panel, Scuttle"
                  }
                ]
              }
            ]
          },
          {
            key: "Body > Frame > Sound Insulation on Body",
            label: "Sound Insulation on Body",
            data: "Sound Insulation on Body",
            selectable: false,
            children: [
              {
                key: "Body > Frame > Sound Insulation on Body > Sound Deadener",
                label: "Sound Deadener",
                data: "Sound Deadener",
                selectable: false,
                children: [
                  {
                    key:
                      "Body > Frame > Sound Insulation on Body > Sound Deadener > Before Paint",
                    label: "Before Paint",
                    data: "Before Paint"
                  },
                  {
                    key:
                      "Body > Frame > Sound Insulation on Body > Sound Deadener > After Paint",
                    label: "After Paint",
                    data: "After Paint"
                  }
                ]
              }
            ]
          },
          {
            key: "Body > Frame > Frontend/Cooling",
            label: "Frontend/Cooling",
            data: "Frontend/Cooling",
            selectable: false,
            children: [
              {
                key: "Body > Frame > Frontend/Cooling > Front Face",
                label: "Front Face",
                data: "Front Face"
              },
              {
                key: "Body > Frame > Frontend/Cooling > Reinforcement",
                label: "Reinforcement",
                data: "Reinforcement"
              }
            ]
          },
          {
            key: "Body > Frame > Screens Aerodynamics",
            label: "Screens Aerodynamics",
            data: "Screens Aerodynamics",
            selectable: false,
            children: [
              {
                key:
                  "Body > Frame > Screens Aerodynamics > Splash Guard Under Engine System",
                label: "Splash Guard Under Engine System",
                data: "Splash Guard Under Engine System"
              },
              {
                key: "Body > Frame > Screens Aerodynamics > Under Rear Axle",
                label: "Under Rear Axle",
                data: "Under Rear Axle"
              },
              {
                key:
                  "Body > Frame > Screens Aerodynamics > Supports - 1st/2nd/3rd",
                label: "Supports - 1st/2nd/3rd",
                data: "Supports - 1st/2nd/3rd"
              }
            ]
          },
          {
            key: "Body > Frame > Waterbox/Draining System",
            label: "Waterbox/Draining System",
            data: "Waterbox/Draining System",
            selectable: false,
            children: [
              {
                key:
                  "Body > Frame > Waterbox/Draining System > Water Drainage Ducts - L/R/Middle/Trunk",
                label: "Water Drainage Ducts - L/R/Middle/Trunk",
                data: "Water Drainage Ducts - L/R/Middle/Trunk"
              },
              {
                key: "Body > Frame > Waterbox/Draining System > Bulkhead",
                label: "Bulkhead ",
                data: "Bulkhead "
              }
            ]
          },
          {
            key: "Body > Frame > Rocker Panel Cover",
            label: "Rocker Panel Cover ",
            data: "Rocker Panel Cover ",
            selectable: false,
            children: [
              {
                key: "Body > Frame > Rocker Panel Cover > Cover",
                label: "Cover",
                data: "Cover"
              },
              {
                key: "Body > Frame > Rocker Panel Cover > Support",
                label: "Support",
                data: "Support"
              }
            ]
          }
        ]
      },
      {
        key: "Body > Fenders",
        label: "Fenders",
        data: "Fenders",
        selectable: false,
        children: [
          {
            key: "Body > Fenders > Front Fenders",
            label: "Front Fenders",
            data: "Front Fenders",
            selectable: false,
            children: [
              {
                key: "Body > Fenders > Front Fenders > Fastener Covers - L/R",
                label: "Fastener Covers - L/R",
                data: "Fastener Covers - L/R"
              },
              {
                key: "Body > Fenders > Front Fenders > Trim Bezel",
                label: "Trim Bezel",
                data: "Trim Bezels"
              },
              {
                key: "Body > Fenders > Front Fenders > Padding",
                label: "Padding",
                data: "Padding"
              }
            ]
          },
          {
            key: "Body > Fenders > Rear Fenders",
            label: "Rear Fenders",
            data: "Rear Fenders"
          }
        ]
      }
    ]
  },
  {
    key: "Interior",
    label: "Interior",
    data: "Events Folder",
    selectable: false,
    children: [
      {
        key: "Interior > Interior Systems",
        label: "Interior Systems",
        data: "Interior Systems",
        selectable: false,
        children: [
          {
            key: "Interior > Interior Systems > Instrument Panel",
            label: "Instrument Panel",
            data: "Instrument Panel ",
            selectable: false,
            children: [
              {
                key: "Interior > Interior Systems > Instrument Panel",
                label: "Dashboard",
                data: "Dashboard "
              },
              {
                key: "Interior > Interior Systems > Cross Car Beam",
                label: "Cross Car Beam",
                data: "Cross Car Beam"
              },
              {
                key: "Interior > Interior Systems > Dashboard Covers",
                label: "Dashboard Covers",
                data: "Dashboard Covers",
                selectable: false,
                children: [
                  {
                    key:
                      "Interior > Interior Systems > Dashboard Covers > Driver Side trip",
                    label: "Driver Side trip",
                    data: "Driver Side trip"
                  },
                  {
                    key:
                      "Interior > Interior Systems > Dashboard Covers > Passenger Side Trim",
                    label: "Passenger Side Trim",
                    data: "Passenger Side Trim "
                  },
                  {
                    key:
                      "Interior > Interior Systems > Dashboard Covers > Central Trim",
                    label: "Central Trim",
                    data: "Central Trim"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        key: "Interior > Seats",
        label: "Seats",
        data: "Seats",
        selectable: false,
        children: [
          {
            key: "Interior > Seats > Driver",
            label: "Driver",
            data: "Driver ",
            selectable: false,
            children: [
              {
                key: "Interior > Seats > Driver > Head Rest",
                label: "Head Rest",
                data: "Head Rest "
              },
              {
                key: "Interior > Seats > Driver > Back",
                label: "Back",
                data: "Back"
              },
              {
                key: "Interior > Seats > Driver > Cushion",
                label: "Cushion",
                data: "Cushion"
              }
            ]
          },
          {
            key: "Interior > Seats > Passenger",
            label: "Passenger",
            data: "Passenger "
          },
          {
            key: "Interior > Seats > Individual Rear Seats",
            label: "Individual Rear Seats",
            data: "Individual Rear Seats "
          }
        ]
      },
      {
        key: "Interior > Safety System",
        label: "Safety System",
        data: "Safety System",
        selectable: false,
        children: [
          {
            key: "Interior > Safety System > Warning Switch",
            label: "Warning Switch",
            data: "Warning Switch "
          },
          {
            key: "Interior > Safety System > Airbags",
            label: "Airbags",
            data: "Airbags "
          },
          {
            key:
              "Interior > Safety System > Seat Belt - Driver, Passenger, RL/RC/RR, 3rd Row",
            label: "Seat Belt - Driver, Passenger, RL/RC/RR, 3rd Row",
            data: "Seat Belt - Driver, Passenger, RL/RC/RR, 3rd Row "
          }
        ]
      }
    ]
  },
  {
    key: "Propulsion (BEV)",
    label: "Propulsion (BEV)",
    data: "Movies Folder",
    selectable: false,
    selectable: false,
    children: [
      {
        key: "Propulsion (BEV) > Engine",
        label: "Engine",
        data: "Engine",
        selectable: false,
        children: [
          {
            key: "Propulsion (BEV) > Engine > Engine mounts",
            label: "Engine mounts",
            data: "Engine mounts",
            selectable: false,
            children: [
              {
                key:
                  "Propulsion (BEV) > Engine > Engine mounts > Engine Mount Left Side System (Transversal Engine)",
                label: "Engine Mount Left Side System (Transversal Engine)",
                data: "Engine Mount Left Side System (Transversal Engine)"
              },
              {
                key:
                  "Propulsion (BEV) > Engine > Engine mounts > Engine Mount Ride Side System (Transversal Engine)",
                label: "Engine Mount Ride Side System (Transversal Engine)",
                data: "Engine Mount Ride Side System (Transversal Engine)"
              }
            ]
          },
          {
            key: "Propulsion (BEV) > Engine > Hybrid/Electric system",
            label: "Hybrid/Electric system",
            data: "Hybrid/Electric system",
            selectable: false,
            children: [
              {
                key:
                  "Propulsion (BEV) > Engine > Hybrid/Electric system > Motor & Transmission",
                label: "Motor & Transmission",
                data: "Motor & Transmission"
              },
              {
                key:
                  "Propulsion (BEV) > Engine > Hybrid/Electric system > Battery System",
                label: "Battery System",
                data: "Battery System"
              },
              {
                key:
                  "Propulsion (BEV) > Engine > Hybrid/Electric system > Power Inverter Module/Converter Module",
                label: "Power Inverter Module/Converter Module",
                data: "Power Inverter Module/Converter Module"
              },
              {
                key:
                  "Propulsion (BEV) > Engine > Hybrid/Electric system > HV Charger System",
                label: "HV Charger System",
                data: "HV Charger System",
                selectable: false,
                children: [
                  {
                    key:
                      "Propulsion (BEV) > Engine > Hybrid/Electric system > HV Charger System > HV Charger",
                    label: "HV Charger",
                    data: "HV Charger"
                  },
                  {
                    key:
                      "Propulsion (BEV) > Engine > Hybrid/Electric system > HV Charger System > HV Charger support",
                    label: "HV Charger support",
                    data: "HV Charger support"
                  }
                ]
              },
              {
                key:
                  "Propulsion (BEV) > Engine > Hybrid/Electric system > Cooling System",
                label: "Cooling System",
                data: "Cooling System",
                selectable: false,
                children: [
                  {
                    key:
                      "Propulsion (BEV) > Engine > Hybrid/Electric system > Cooling System > Expansion Bottle System",
                    label: "Expansion Bottle System",
                    data: "Expansion Bottle System"
                  },
                  {
                    key:
                      "Propulsion (BEV) > Engine > Hybrid/Electric system > Cooling System > Cooling System for Battery",
                    label: "Cooling System for Battery",
                    data: "Cooling System for Battery"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    key: "Drive-train & chasis",
    label: "Drive-train & chasis",
    data: "Movies Folder",
    selectable: false,
    children: [
      {
        key: "Drive-train & chasis > Suspension",
        label: "Suspension",
        data: "Suspension",
        selectable: false,
        children: [
          {
            key: "Drive-train & chasis > Suspension > Shocks absorbers",
            label: "Shocks absorbers",
            data: "Shocks absorbers",
            selectable: false,
            children: [
              {
                key:
                  "Drive-train & chasis > Suspension > Shocks absorbers > Front - shock absorber + lower leg",
                label: "Front - shock absorber + lower leg",
                data: "Front - shock absorber + lower leg"
              }
            ]
          }
        ]
      },
      {
        key: "Drive-train & chasis > Steering System",
        label: "Steering System",
        data: "Steering System"
      }
    ]
  },
  {
    key: "Internal Systems",
    label: "Internal Systems",
    data: "Movies Folder",
    selectable: false,
    children: [
      {
        key: "Internal Systems > Electrical",
        label: "Electrical",
        data: "Electrical",
        selectable: false,
        children: [
          {
            key: "Internal Systems > Electrical > Battery",
            label: "Battery",
            data: "Battery"
          }
        ]
      },
      {
        key: "Internal Systems > Wiring",
        label: "Wiring",
        data: "Wiring"
      }
    ]
  },
  {
    key: "Technology",
    label: "Technology",
    data: "Movies Folder",
    selectable: false,
    children: [
      {
        key: "Technology-Accessories",
        label: "Accessories",
        data: "Accessories"
      }
    ]
  }
];
