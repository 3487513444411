import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { companyService } from "../../_services/admin/company.service";
import { ProgressSpinner } from "primereact/progressspinner";
import moment from "moment";
import queryString from "query-string";
import { Toast } from "primereact/toast";

export default class CompanyFeaturedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      companies: [],
      globalFilter: null,
      loading: true,
      featuredCompanyLastSearch: ""
    };
  }
  componentDidMount() {
    this.getAllCompanies();
    this.setLastSearchedValues();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setLastSearchedValues = () => {
    const { f: featuredCompany } = queryString.parse(
      this.props.location.search
    );

    if (featuredCompany) {
      this.setState({
        globalFilter: featuredCompany,
        featuredCompanyLastSearch: featuredCompany
      });
    }
  };

  setFilterOnBack = () => {
    const { f: featuredCompany } = queryString.parse(
      this.props.location.search
    );

    if (featuredCompany) {
      this.setState({
        globalFilter: featuredCompany,
        featuredCompanyLastSearch: featuredCompany
      });
    } else {
      this.setState({
        globalFilter: "",
        featuredCompanyLastSearch: ""
      });
    }
  };

  showToastMessages = (severity, details) => {
    this.toast.show({
      severity: "success",
      detail: "File Downloaded Successfully"
    });
  };

  getAllCompanies = () => {
    companyService.getAllCompanies().then((data) => {
      if (data && data.Status) {
        this.setState({ companies: data.Data, loading: false });
      }
    });
  };

  donwloadCompanyDataInExcelFormat = (companyId, companyName) => {
    try {
      companyService
        .generateCompanyExcelSheet(companyId, companyName)
        .then((data) => {
          this.showToastMessages("success", "File Downloaded Successfully");
        });
    } catch (error) {
      this.showToastMessages("warning", error.message);
    }
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container featured-action-btn">
        {" "}
        <button
          type="button"
          class="btn btn-success btn-sm"
          onClick={(e) => {
            this.donwloadCompanyDataInExcelFormat(
              rowData._id,
              rowData.demographics.companyTitle
            );
          }}
        >
          Donwload
        </button>
      </div>
    );
  };

  dateTemplate = (rowData) => {
    return moment(rowData.createdAt).format("MM/DD/YYYY hh:mm a");
  };

  appendQueryStringOnTextChangeinURL = (searchedCompany) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/export-company-data",
        search: `?f=${searchedCompany}`
      });
    } else {
      this.props.history.replace({
        pathname: "/export-company-data",
        search: `?f=${searchedCompany}`
      });
    }
  };

  downloadFileForAllCompany = () => {
    this.infoToast.show({
      severity: "info",
      summary: "Please wait while genenrating file!",
      sticky: true
    });

    try {
      companyService.generateAllCompanyExcelSheet().then(() => {
        this.showToastMessages("success", "File Downloaded Successfully");
        this.clearMessages();
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  clearMessages() {
    this.infoToast.clear();
  }

  render() {
    const items = [{ label: "Export Company Data" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    const { featuredCompanyLastSearch, companies, globalFilter } = this.state;

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <Toast ref={(el) => (this.toast = el)} position="top-right"></Toast>
        <Toast ref={(el) => (this.infoToast = el)} position="top-right"></Toast>

        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="mb-3 table-header">
                  <h2>Export Company Data</h2>
                  <span>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm ml-100"
                      onClick={(e) => {
                        this.downloadFileForAllCompany();
                      }}
                    >
                      Donwload All Company Data
                    </button>
                  </span>

                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState(
                          {
                            globalFilter: e.target.value,
                            featuredCompanyLastSearch: e.target.value
                          },
                          () => {
                            this.appendQueryStringOnTextChangeinURL(
                              this.state.featuredCompanyLastSearch
                            );
                          }
                        )
                      }
                      placeholder="Search"
                      value={featuredCompanyLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={companies}
                    className="p-datatable-striped"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={globalFilter}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    <Column field="email" header="Email" sortable></Column>
                    <Column
                      field="createdAt"
                      header="Created"
                      body={this.dateTemplate}
                      sortable
                    ></Column>
                    <Column body={this.actionTemplate} header="Action"></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
