import React from "react";
import DashboardLayout from "./DashboardLayout";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "../../_services";
import { Role } from "../../_helpers";
const DashboardLayoutRoute = ({ component: Component, roles, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: matchProps.location } }}
            />
          );
        }
        if (roles && roles.role !== currentUser.role) {
          return currentUser.role === Role.SuperAdmin ? (
            <Redirect to={{ pathname: "/super-admin" }} />
          ) : (
            <Redirect to={{ pathname: "/admin" }} />
          );
        }
        return (
          <DashboardLayout>
            <Component {...matchProps} />
          </DashboardLayout>
        );
      }}
    />
  );
};

export default DashboardLayoutRoute;
