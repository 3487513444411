import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import GoogleMapReact from "google-map-react";
import { MapMarker } from "../../components/MapMarker";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { Dropdown } from "primereact/dropdown";
import { getTenYearBeforeAndAfterCurrentYear } from "../../_helpers/_helperFunctions";
import { companyService } from "../../_services/admin/company.service";
import moment from "moment";
//am4core.useTheme(am4themes_animated);

const mapOptions = {
  center: {
    lat: 42.2868836,
    lng: -82.9286349
  },
  zoom: 12
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companiesLocation: [],
      year: new Date().getFullYear()
    };
  }

  componentDidMount() {
    this.getMonthAppointMentRecord(this.state.year);
    this.headQuartersLocation();
    // this.cammMemberChart();
    // this.hiringChart();
    // this.activeChart();
    this.activeCompaniesLatLng();
  }

  activeCompaniesLatLng = () => {
    companyService.getAllActiveCompaniesLatLong().then((data) => {
      this.setState({ companiesLocation: data.Data });
    });
  };

  getMonthAppointMentRecord = (year) => {
    companyService.monthlyCompaniesEnrollmentRecord(year).then((data) => {
      let chart = am4core.create(
        "getMonthAppointMentRecord",
        am4charts.XYChart
      );

      // Add data
      let monthlyData = [
        {
          month: `Jan ${year}`,
          visits: 0
        },
        {
          month: "Feb",
          visits: 0
        },
        {
          month: "Mar",
          visits: 0
        },
        {
          month: "Apr",
          visits: 0
        },
        {
          month: "May ",
          visits: 0
        },
        {
          month: "Jun",
          visits: 0
        },
        {
          month: "Jul",
          visits: 0
        },
        {
          month: "Aug",
          visits: 0
        },
        {
          month: "Sep",
          visits: 0
        },
        {
          month: "Oct",
          visits: 0
        },
        {
          month: "Nov",
          visits: 0
        },
        {
          month: "Dec",
          visits: 0
        }
      ];

      data.Data.map((item) => {
        monthlyData[item._id - 1].visits = item.count;
      });

      chart.data = monthlyData;
      // Create axes

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "month";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;

      categoryAxis.renderer.labels.template.adapter.add(
        "dy",
        function (dy, target) {
          if (target.dataItem && target.dataItem.index & (2 == 2)) {
            return dy + 25;
          }
          return dy;
        }
      );

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      let title5 = chart.titles.create();
      title5.text = `Monthly IWE Registrations`;
      title5.fontSize = 18;

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "visits";
      series.dataFields.categoryX = "month";
      series.name = "Visits";
      series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
      series.columns.template.fillOpacity = 0.8;

      let columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;
      chart.exporting.menu = new am4core.ExportMenu();
    });
  };

  headQuartersLocation = () => {
    companyService.getAllHeadQuartersLocation().then((data) => {
      // Create map instance
      let chart = am4core.create("headQuartersLocation", am4maps.MapChart);
      // Set Title

      let title5 = chart.titles.create();
      title5.text = `HQ Location's`;
      title5.fontSize = 18;

      // Set map definition
      chart.geodata = am4geodata_worldLow;

      // Set projection
      chart.projection = new am4maps.projections.Miller();

      // Create map polygon series
      let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      // Exclude Antartica
      polygonSeries.exclude = ["AQ"];

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;

      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = "{name}";
      polygonTemplate.polygon.fillOpacity = 0.6;

      // Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create("hover");
      hs.properties.fill = chart.colors.getIndex(0);

      // Add image series
      let imageSeries = chart.series.push(new am4maps.MapImageSeries());
      imageSeries.mapImages.template.propertyFields.longitude = "longitude";
      imageSeries.mapImages.template.propertyFields.latitude = "latitude";
      imageSeries.mapImages.template.tooltipText = "{title}";
      imageSeries.mapImages.template.propertyFields.url = "url";

      let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle.radius = 3;
      circle.propertyFields.fill = "color";

      let circle2 = imageSeries.mapImages.template.createChild(am4core.Circle);
      circle2.radius = 3;
      circle2.propertyFields.fill = "color";

      circle2.events.on("inited", function (event) {
        animateBullet(event.target);
      });

      function animateBullet(circle) {
        let animation = circle.animate(
          [
            { property: "scale", from: 1, to: 5 },
            { property: "opacity", from: 1, to: 0 }
          ],
          1000,
          am4core.ease.circleOut
        );
        animation.events.on("animationended", function (event) {
          animateBullet(event.target.object);
        });
      }
      let colorSet = new am4core.ColorSet();

      data.Data.forEach((company) => {
        imageSeries.data.push({
          title: company.concatedTitle,
          latitude: company?.latAndLong?.latitude,
          longitude: company?.latAndLong?.longitude,
          color: colorSet.next()
        });
      });
    });
  };

  year = getTenYearBeforeAndAfterCurrentYear();

  render() {
    const { companiesLocation } = this.state;

    let filteredCompaniesLocation = companiesLocation.filter((companies) => {
      return companies?.location?.coordinates.length;
    });
    const items = [{ label: "Analytics" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />

        <div className="p-grid">
          <div className="p-col-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  {" "}
                  <h1> Registered Companies </h1>
                  <div style={{ height: "50vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyD6GGik6mcxsZbKw60nof5NwYubIleeSYE"
                      }}
                      defaultCenter={mapOptions.center}
                      defaultZoom={mapOptions.zoom}
                    >
                      {filteredCompaniesLocation.map((company, index) => {
                        return (
                          <MapMarker
                            key={index}
                            lat={company?.location?.coordinates[0]}
                            lng={company?.location?.coordinates[1]}
                            text={company?.demographics?.companyTitle}
                            address={company?.demographics?.address}
                          />
                        );
                      })}
                    </GoogleMapReact>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card monthly-register-panel">
                  <Dropdown
                    style={{ width: "10%", textAlign: "right" }}
                    value={this.state.year}
                    options={this.year}
                    onChange={(e) => {
                      this.setState({ year: e.value });
                      this.getMonthAppointMentRecord(e.value);
                    }}
                    placeholder="Select a year"
                  />
                  <div
                    id="getMonthAppointMentRecord"
                    style={{ width: "100%", height: "500px" }}
                  ></div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card">
                  <div
                    id="headQuartersLocation"
                    style={{ width: "100%", height: "400px" }}
                  ></div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-4">
                <div className="card">
                  <div
                    id="camMember"
                    style={{ width: "100%", height: "400px" }}
                  ></div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div
                    id="hiringChart"
                    style={{ width: "100%", height: "400px" }}
                  ></div>
                </div>  
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div
                    id="activeChart"
                    style={{ width: "100%", height: "400px" }}
                  ></div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
