import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { authenticationService } from "../../_services/authentication.service";
import moment from "moment";
import queryString from "query-string";
export class AgreedCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: authenticationService.currentUserValue,
      isAgreedCompletedCompanies: [],
      isAgreedCompletedCompaniesGlobalFilter: null,
      isAgreedCompaniesLastSearch: ""
    };
  }

  componentDidMount() {
    this.isAgreedCompletedCompanies();
    this.setInitialFilteredValueInSearchBar();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setInitialFilteredValueInSearchBar = () => {
    const { verifiedCompany } = queryString.parse(this.props.location.search);
    if (verifiedCompany) {
      this.setState({
        isAgreedCompletedCompaniesGlobalFilter: verifiedCompany,
        isAgreedCompaniesLastSearch: verifiedCompany
      });
    }
  };

  setFilterOnBack = () => {
    const { verifiedCompany } = queryString.parse(this.props.location.search);

    if (verifiedCompany) {
      this.setState({
        isAgreedCompletedCompaniesGlobalFilter: verifiedCompany,
        isAgreedCompaniesLastSearch: verifiedCompany
      });
    } else {
      this.setState({
        isAgreedCompletedCompaniesGlobalFilter: "",
        isAgreedCompaniesLastSearch: ""
      });
    }
  };

  isAgreedCompletedCompanies = () => {
    companyService.isAgreedCompletedCompanies().then((data) => {
      this.setState({ isAgreedCompletedCompanies: data.Data });
    });
  };

  dateTemplate = (rowData) => {
    return moment(rowData.updatedAt).format("MM/DD/YYYY hh:mm a");
  };

  appendQueryStringOnTextChangeinURL = (companyName) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/verified-by-company",
        search: `?verifiedCompany=${companyName}`
      });
    } else {
      this.props.history.push({
        pathname: "/verified-by-company",
        search: `?verifiedCompany=${companyName}`
      });
    }
  };

  render() {
    const items = [{ label: "Verified by Company" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <div className="mb-3 table-header">
                  <h2>Verified by Company</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) => {
                        this.setState(
                          {
                            isAgreedCompletedCompaniesGlobalFilter:
                              e.target.value,
                            isAgreedCompaniesLastSearch: e.target.value
                          },
                          () => {
                            this.appendQueryStringOnTextChangeinURL(
                              this.state.isAgreedCompletedCompaniesGlobalFilter
                            );
                          }
                        );
                      }}
                      placeholder="Search"
                      value={this.state.isAgreedCompaniesLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={this.state.isAgreedCompletedCompanies}
                    className="p-datatable-striped"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={
                      this.state.isAgreedCompletedCompaniesGlobalFilter
                    }
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    <Column field="email" header="Email" sortable></Column>
                    <Column
                      body={this.dateTemplate}
                      header="Updated"
                      className="text-center"
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AgreedCompanies;
