
import {createStore,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger'
import {reducer} from './reducer';

// This connects the reducer to the store
export default function configureStore() {
  let store = createStore(
    reducer,
    applyMiddleware(thunk,logger)
  )

  return store
}