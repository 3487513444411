import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";
import axios from "axios";
export const companyService = {
  register,
  getCompanyById,
  updateProfile,
  updateLogo,
  getAllCompanies,
  blockCompany,
  getAllPendingProfileRequest,
  getAllRejectedProfileRequest,
  getAllApprovedProfileRequest,
  approveProfileRequest,
  rejectProfileRequest,
  getProfileRequestById,
  getAllActiveCompanies,
  assignBadges,
  tagCompanyAsFeatured,
  tagCompanyAsUnFeatured,
  changePassword,
  apmiHistory,
  getAllAPMIHistory,
  getFilesForSpecificCompany,
  cammMemberStats,
  activeStats,
  hiringStats,
  getAllActiveCompaniesLatLong,
  monthlyCompaniesEnrollmentRecord,
  getAllHeadQuartersLocation,
  getAllUnplishedCompanies,
  getAllPublishedCompanies,
  tagCompanyAsUnPublished,
  tagCompanyAsPublished,
  getFilesByCompanyId,
  deleteResourceFile,
  saveFile,
  saveServiceKeyword,
  permanentlyDeleteCompany,
  getSignedLinkFromKey,
  isAgreedCompletedCompanies,
  changeCompanyActiveStatus,
  generateCompanyExcelSheet,
  generateAllCompanyExcelSheet
};

function changeCompanyActiveStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/change-company-active-status`,
    requestOptions
  ).then(handleResponse);
}

function register(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      isenc: localStorage.getItem("isenc")
        ? parseInt(localStorage.getItem("isenc"))
        : 0
    },
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/register`,
    requestOptions
  ).then(handleResponse);
}

function getCompanyById(companyId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/${companyId}`,
    requestOptions
  ).then(handleResponse);
}

function updateProfile(companyId, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/${companyId}`,
    requestOptions
  ).then(handleResponse);
}

function updateLogo(companyId, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/logo-image/${companyId}`,
    requestOptions
  ).then(handleResponse);
}

function getAllCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/api/v1/companies`, requestOptions).then(
    handleResponse
  );
}

function blockCompany(companyId, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/block/${companyId}`,
    requestOptions
  ).then(handleResponse);
}

function getAllPendingProfileRequest() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/profile-change-requests`,
    requestOptions
  ).then(handleResponse);
}

function getAllRejectedProfileRequest() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/profile-change-requests?type=rejected`,
    requestOptions
  ).then(handleResponse);
}

function getAllApprovedProfileRequest() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/profile-change-requests?type=approved`,
    requestOptions
  ).then(handleResponse);
}

function approveProfileRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/profile-change-requests/approve`,
    requestOptions
  ).then(handleResponse);
}

function rejectProfileRequest(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/profile-change-requests/reject`,
    requestOptions
  ).then(handleResponse);
}

function getProfileRequestById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/profile-change-requests/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getAllActiveCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies?type=active`,
    requestOptions
  ).then(handleResponse);
}

function assignBadges(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/badges/assign`,
    requestOptions
  ).then(handleResponse);
}

function tagCompanyAsFeatured(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/featured`,
    requestOptions
  ).then(handleResponse);
}

function tagCompanyAsUnFeatured(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/unfeatured`,
    requestOptions
  ).then(handleResponse);
}

function changePassword(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/changepassword`,
    requestOptions
  ).then(handleResponse);
}

function apmiHistory(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(`${config.apiUrl}/api/v1/companies/apmi`, requestOptions).then(
    handleResponse
  );
}

function getAllAPMIHistory() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/api/v1/companies/apmi`, requestOptions).then(
    handleResponse
  );
}

function getFilesForSpecificCompany(companyId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/companies/company-files/${companyId}`,
    requestOptions
  ).then(handleResponse);
}

function cammMemberStats() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/companies/cammMemberStats`,
    requestOptions
  ).then(handleResponse);
}

function activeStats() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/companies/activeStats`,
    requestOptions
  ).then(handleResponse);
}

function hiringStats() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/companies/hiringStats`,
    requestOptions
  ).then(handleResponse);
}

function getAllActiveCompaniesLatLong() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/companies/companies-location`,
    requestOptions
  ).then(handleResponse);
}

function monthlyCompaniesEnrollmentRecord(year) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(
    `${config.apiUrl}/api/v1/companies/monthly-enrollment-record/${year}`,
    requestOptions
  ).then(handleResponse);
}

function getAllHeadQuartersLocation() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/getAllHeadQuartersLocation`,
    requestOptions
  ).then(handleResponse);
}

function getAllPublishedCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/all-published-company`,
    requestOptions
  ).then(handleResponse);
}

function getAllUnplishedCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/all-unpublished-company`,
    requestOptions
  ).then(handleResponse);
}

function tagCompanyAsPublished(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/publish-company`,
    requestOptions
  ).then(handleResponse);
}

function tagCompanyAsUnPublished(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/unpublish-company`,
    requestOptions
  ).then(handleResponse);
}
function getFilesByCompanyId(companyId) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/files/${companyId}`,
    requestOptions
  ).then(handleResponse);
}
function deleteResourceFile(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/files/delete`,
    requestOptions
  ).then(handleResponse);
}

function saveFile(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/files/save`,
    requestOptions
  ).then(handleResponse);
}
function saveServiceKeyword(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/saveServiceKeyword`,
    requestOptions
  ).then(handleResponse);
}

function permanentlyDeleteCompany(companyId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/permanently-delete-all-data/${companyId}`,
    requestOptions
  ).then(handleResponse);
}

function getSignedLinkFromKey(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: encData(data)
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/files/get-file-url`,
    requestOptions
  ).then(handleResponse);
}

function isAgreedCompletedCompanies() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(
    `${config.apiUrl}/api/v1/companies/agreed-companies`,
    requestOptions
  ).then(handleResponse);
}

function generateCompanyExcelSheet(companyId, companyName) {
  return axios({
    method: "post",
    url: `${config.apiUrl}/api/v1/companies/get-user-data-excel-sheet/${companyId}`,
    data: {},
    headers: authHeader(),
    responseType: "blob"
  }).then((res) => {
    const blob = new Blob(
      [res.data],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      companyName
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${companyName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return url;
  });
}

function generateAllCompanyExcelSheet() {
  return axios({
    method: "post",
    url: `${config.apiUrl}/api/v1/companies/get-all-company-data-excel-sheet`,
    data: {},
    headers: authHeader(),
    responseType: "blob"
  }).then((res) => {
    const blob = new Blob(
      [res.data],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      "CompanyData"
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `CompanyData.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return url;
  });
}
