import React, { Component } from "react";

export class AppFooter extends Component {
  render() {
    return (
      <div className="layout-footer">
        <span className="footer-text">Invest WindsorEssex</span>
      </div>
    );
  }
}
