import React, { Component } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import Profile from "../../components/APMA/Profile";
import MutualNDA from "../../components/APMA/MutualNDA";
import ArrowPartnershipAgreement from "../../components/APMA/ArrowPartnershipAgreement";
import RFP from "../../components/APMA/RFP";
export default class ApmaProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1"
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };
  render() {
    const { activeTab } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    console.log("id: ", id);
    const items = [
      { label: "Apma Company List", url: "/apma-company-list" },
      { label: "Apma Company Profile" }
    ];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="card">
              <Nav tabs>
                <NavItem className="apma-forms-tab">
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem className="apma-forms-tab">
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "2"
                    })}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                  >
                    Mutual NDA
                  </NavLink>
                </NavItem>
                {/* <NavItem className="apma-forms-tab">
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "3"
                    })}
                    onClick={() => {
                      this.toggleTab("3");
                    }}
                  >
                    Arrow Partnership Agreement
                  </NavLink>
                </NavItem> */}
                <NavItem className="apma-forms-tab">
                  <NavLink
                    className={classnames("cursor-pointer", {
                      active: activeTab === "4"
                    })}
                    onClick={() => {
                      this.toggleTab("4");
                    }}
                  >
                    RFP
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12" md="12">
                      <Profile id={id} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12" md="12">
                      <MutualNDA id={id} />
                    </Col>
                  </Row>
                </TabPane>
                {/* <TabPane tabId="3">
                  <Row>
                    <Col sm="12" md="12">
                      <ArrowPartnershipAgreement id={id} />
                    </Col>
                  </Row>
                </TabPane> */}
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12" md="12">
                      <RFP id={id} />{" "}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </>
    );
  }
}
