import config from "../../config";
import { authHeader, handleResponse } from "../../_helpers";
import { encData } from "../../_helpers/_helperFunctions";

export const apmaServices = {
  getAllCompanies,
  getApmaProfileById
};

function getAllCompanies() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.apiUrl}/api/v1/apmas/companies`, requestOptions).then(
    handleResponse
  );
}

function getApmaProfileById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };
  return fetch(`${config.apiUrl}/api/v1/apmas/${id}`, requestOptions).then(
    handleResponse
  );
}
