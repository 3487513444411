import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "primereact/tooltip";
import {
  faPencilAlt,
  faEye,
  faThumbsUp,
  faThumbsDown,
  faHandPaper
} from "@fortawesome/free-solid-svg-icons";
import { Dialog } from "primereact/dialog";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { authenticationService } from "../../_services";
import { SECTIONS, COMPANYPROFILEFIELDS } from "../../_helpers/common";
import moment from "moment";
import swal from "sweetalert";
import { highLightInProfileChangeRequest } from "../../_helpers/_helperFunctions";
import queryString from "query-string";

export default class CompanyProfileRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileRequests: [],
      approvedRequests: [],
      currentUser: authenticationService.currentUserValue,
      selectedProfileRequest: {},
      pendingListGlobalFilter: null,
      approvedListGlobalFilter: null,
      profileViewModal: false,
      approvedCompanyLastSearched: "",
      pendingCompanyLastSearched: ""
    };
  }

  componentDidMount() {
    this.setLastSearchedValues();
    this.getAllPendingProfileRequest();
    this.getAllApprovedProfileRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setLastSearchedValues = () => {
    const { a: approvedCompany, p: pendingCompany } = queryString.parse(
      this.props.location.search
    );

    if (approvedCompany) {
      this.setState({
        approvedListGlobalFilter: approvedCompany,
        approvedCompanyLastSearched: approvedCompany
      });
    }
    if (pendingCompany) {
      this.setState({
        pendingListGlobalFilter: pendingCompany,
        pendingCompanyLastSearched: pendingCompany
      });
    }
  };

  setFilterOnBack = () => {
    const { a: approvedCompany, p: pendingCompany } = queryString.parse(
      this.props.location.search
    );

    if (approvedCompany) {
      this.setState({
        approvedListGlobalFilter: approvedCompany,
        approvedCompanyLastSearched: approvedCompany
      });
    } else {
      this.setState({
        approvedListGlobalFilter: "",
        approvedCompanyLastSearched: ""
      });
    }
    if (pendingCompany) {
      this.setState({
        pendingListGlobalFilter: pendingCompany,
        pendingCompanyLastSearched: pendingCompany
      });
    } else {
      this.setState({
        pendingListGlobalFilter: "",
        pendingCompanyLastSearched: ""
      });
    }
  };

  getAllPendingProfileRequest = () => {
    companyService.getAllPendingProfileRequest().then((data) => {
      if (data && data.Status) {
        this.setState({ profileRequests: data.Data });
      }
    });
  };

  getAllApprovedProfileRequest = () => {
    companyService.getAllApprovedProfileRequest().then((data) => {
      if (data && data.Status) {
        this.setState({ approvedRequests: data.Data });
      }
    });
  };

  approveRequest = (profileRequestId) => {
    let requestData = {
      profileRequestId,
      currentUserId: this.state.currentUser.id
    };
    companyService.approveProfileRequest(requestData).then((data) => {
      if (data && data.Status) {
        this.getAllPendingProfileRequest();
        this.getAllApprovedProfileRequest();
        swal(data.Data.message, "", "success");
      }
    });
  };

  rejectRequest = (profileRequestId) => {
    let requestData = {
      profileRequestId,
      currentUserId: this.state.currentUser.id
    };

    swal({
      title: "Are you sure?",
      text: "You want to reject this request.",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        companyService.rejectProfileRequest(requestData).then((data) => {
          if (data && data.Status) {
            this.getAllPendingProfileRequest();
            swal("Profile Request Rejected", "", "success");
          }
        });
      }
    });
  };

  getProfileRequestById = (profileRequestId) => {
    companyService.getProfileRequestById(profileRequestId).then((data) => {
      if (data && data.Status) {
        this.setState({
          selectedProfileRequest: data.Data,
          profileViewModal: true
        });
      }
    });
  };

  toggleProfileView = (profileRequestId) => {
    this.getProfileRequestById(profileRequestId);
  };

  actionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.toggleProfileView(rowData._id)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
        <span
          className="cursor-pointer text-primary"
          data-toggle="tooltip"
          title="Edit"
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </span>
        <span
          className="cursor-pointer text-success"
          data-toggle="tooltip"
          title="Approve"
          onClick={(e) => this.approveRequest(rowData._id)}
        >
          <FontAwesomeIcon icon={faThumbsUp} />
        </span>
        <span
          className="cursor-pointer text-danger"
          data-toggle="tooltip"
          title="Disapprove"
          onClick={(e) => this.rejectRequest(rowData._id)}
        >
          <FontAwesomeIcon icon={faThumbsDown} />
        </span>
        <span
          className="cursor-pointer text-warning"
          data-toggle="tooltip"
          title="Put on Hold"
        >
          <FontAwesomeIcon icon={faHandPaper} />
        </span>
      </div>
    );
  };

  approveActionTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer"
          data-toggle="tooltip"
          title="View"
          onClick={(e) => this.toggleProfileView(rowData._id)}
        >
          <FontAwesomeIcon icon={faEye} />
        </span>
      </div>
    );
  };

  sectionTemplate = (rowData) => {
    return SECTIONS[rowData.section];
  };

  openProfileModal = () => {
    this.setState({ profileViewModal: true });
  };

  closeProfileModal = () => {
    this.setState({ profileViewModal: false });
  };

  dateTemplate = (rowData) => {
    return moment(rowData.createdAt).format("MM/DD/YYYY hh:mm a");
  };

  appendQueryStringOnTextChangeinURL = (pending, approve) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/company-profile-requests",
        search: `?a=${approve}&p=${pending}`
      });
    } else {
      this.props.history.replace({
        pathname: "/company-profile-requests",
        search: `?a=${approve}&p=${pending}`
      });
    }
  };

  render() {
    let { selectedProfileRequest } = this.state;
    const items = [{ label: "Company Profile List" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    const pendingListHeader = (
      <div className="table-header">
        Pending Company Profile Request List
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) =>
              this.setState({ pendingListGlobalFilter: e.target.value })
            }
            placeholder="Search"
          />
        </span>
      </div>
    );

    const approvedListHeader = (
      <div className="table-header">
        Approved Company Profile Request List
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) =>
              this.setState({ approvedListGlobalFilter: e.target.value })
            }
            placeholder="Search"
          />
        </span>
      </div>
    );

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              {this.state.profileRequests.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>Pending Company Profile Request List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState(
                            {
                              pendingListGlobalFilter: e.target.value,
                              pendingCompanyLastSearched: e.target.value
                            },
                            () => {
                              this.appendQueryStringOnTextChangeinURL(
                                this.state.pendingCompanyLastSearched,
                                this.state.approvedCompanyLastSearched
                              );
                            }
                          )
                        }
                        placeholder="Search"
                        value={this.state.pendingCompanyLastSearched}
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.profileRequests}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.pendingListGlobalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="companyTitle"
                        header="Company Title"
                        sortable
                      ></Column>
                      {/* <Column
                        field="industry"
                        header="Industry"
                        sortable
                      ></Column> */}
                      <Column
                        field="section"
                        body={this.sectionTemplate}
                        header="Section"
                        sortable
                      ></Column>
                      <Column
                        field="createdAt"
                        header="Modified"
                        body={this.dateTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.actionTemplate}
                        header="Action"
                        className="text-center"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">No Pending Request Available</h3>
                </div>
              )}
            </div>
          </div>

          <div className="p-col-12">
            <div className="datatable-filter">
              {this.state.approvedRequests.length ? (
                <div className="card">
                  <div className="mb-3 table-header">
                    <h2>Approved Company Profile Request List</h2>
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        onInput={(e) =>
                          this.setState(
                            {
                              approvedListGlobalFilter: e.target.value,
                              approvedCompanyLastSearched: e.target.value
                            },
                            () => {
                              this.appendQueryStringOnTextChangeinURL(
                                this.state.pendingCompanyLastSearched,
                                this.state.approvedCompanyLastSearched
                              );
                            }
                          )
                        }
                        placeholder="Search"
                        value={this.state.approvedCompanyLastSearched}
                      />
                    </span>
                  </div>
                  <div className="custom-table-responsive ">
                    <DataTable
                      value={this.state.approvedRequests}
                      className="p-datatable-striped"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                      rows={10}
                      globalFilter={this.state.approvedListGlobalFilter}
                      rowsPerPageOptions={[10, 20, 50]}
                    >
                      <Column
                        field="companyTitle"
                        header="Company Title"
                        sortable
                      ></Column>
                      {/* <Column
                        field="industry"
                        header="Industry"
                        sortable
                      ></Column> */}
                      <Column
                        field="section"
                        body={this.sectionTemplate}
                        header="Section"
                        sortable
                      ></Column>
                      <Column
                        field="createdAt"
                        header="Modified"
                        body={this.dateTemplate}
                        sortable
                      ></Column>
                      <Column
                        body={this.approveActionTemplate}
                        header="Action"
                        className="text-center"
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <h3 className="text-center">No Approved Request Available</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <Dialog
          header={selectedProfileRequest.companyTitle || "Profile"}
          visible={this.state.profileViewModal}
          style={{ width: "90vw" }}
          onHide={this.closeProfileModal}
        >
          {selectedProfileRequest.section ? (
            <div>
              <h3 className="text-center">
                Changes in section - {SECTIONS[selectedProfileRequest.section]}:
              </h3>
              <div className="row">
                <div className="col-md-4">
                  <b>Field Name</b>
                </div>
                <div className="col-md-4">
                  <b>Old Value</b>
                </div>
                <div className="col-md-4">
                  <b>New Value</b>
                </div>
              </div>
              <hr />
              {Object.keys(selectedProfileRequest.sectionData).map(
                (field, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-4">
                        {highLightInProfileChangeRequest(
                          selectedProfileRequest?.oldCompanyData[field],
                          selectedProfileRequest?.sectionData[field],
                          field
                        ) == "0" ? (
                          <p className="py-1">{COMPANYPROFILEFIELDS[field]}</p>
                        ) : (
                          <p className="py-1">
                            <strong style={{ background: "yellow" }}>
                              {COMPANYPROFILEFIELDS[field]}
                            </strong>
                          </p>
                        )}
                      </div>
                      <div className="col-md-4">
                        <p className="py-1"></p>
                        <p className="py-1">
                          {selectedProfileRequest?.oldCompanyData[field]}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p className="py-1">
                          {field == "isHiring"
                            ? selectedProfileRequest?.sectionData[field] &&
                              selectedProfileRequest?.sectionData[
                                field
                              ].toString()
                            : ""}
                        </p>
                        <p className="py-1">
                          {selectedProfileRequest?.sectionData[field]}
                        </p>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div>
              <h3>Loading...</h3>
            </div>
          )}
        </Dialog>
      </>
    );
  }
}
