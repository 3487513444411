import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faThumbsDown,
  faThumbsUp,
  faTrash,
  faTrashAlt,
  faUserCheck
} from "@fortawesome/free-solid-svg-icons";
import { BreadCrumb } from "primereact/breadcrumb";
import { companyService } from "../../_services/admin/company.service";
import { authenticationService } from "../../_services";
import queryString from "query-string";
import moment from "moment";
import swal from "sweetalert";

export default class CompanyRegistrationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      companies: [],
      globalFilterforUnblockedCompanies: null,
      globalFilterforblockedCompanies: null,
      blockedCompanies: [],
      unblockedCompanies: [],
      pendingConfirmationcompanies: [],
      companyNameLastSearch: "",
      companyNameLastSearchDeleted: ""
    };
  }

  componentDidMount() {
    this.getAllCompanies();
    const { req: registeredCompany, del: deleteCompanyName } =
      queryString.parse(this.props.location.search);

    if (registeredCompany) {
      this.setState({
        globalFilterforUnblockedCompanies: registeredCompany,
        companyNameLastSearch: registeredCompany
      });
    }
    if (deleteCompanyName) {
      this.setState({
        globalFilterforblockedCompanies: deleteCompanyName,
        companyNameLastSearchDeleted: deleteCompanyName
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.search != this.props.history.location.search) {
      this.setFilterOnBack();
    }
  }

  setFilterOnBack = () => {
    const { req: registeredCompany, del: deleteCompanyName } =
      queryString.parse(this.props.location.search);

    if (registeredCompany) {
      this.setState({
        globalFilterforUnblockedCompanies: registeredCompany,
        companyNameLastSearch: registeredCompany
      });
    } else {
      this.setState({
        globalFilterforUnblockedCompanies: "",
        companyNameLastSearch: ""
      });
    }
    if (deleteCompanyName) {
      this.setState({
        globalFilterforblockedCompanies: "",
        companyNameLastSearchDeleted: ""
      });
    } else {
      this.setState({
        globalFilterforblockedCompanies: "",
        companyNameLastSearchDeleted: ""
      });
    }
  };

  companyList = [
    {
      companyTitle: "Marks and Sons",
      industry: "Internet Solution",
      createdAt: "09/12/2020 02:59:32 AM"
    },
    {
      companyTitle: "Gleichner - Gerlach",
      industry: "E-Commerce",
      createdAt: "09/24/2020 09:47:54 PM"
    },
    {
      companyTitle: "Padberg - Wiza",
      industry: "E-Business",
      createdAt: "09/26/2020 03:00:42 AM"
    },
    {
      companyTitle: "Rice Inc",
      industry: "Food Tech",
      createdAt: "09/29/2020 06:55:03 AM"
    },
    {
      companyTitle: "Kohler Inc",
      industry: "Networks",
      createdAt: "10/01/2020 10:58:29 AM"
    }
  ];

  getAllCompanies = () => {
    companyService.getAllCompanies().then((data) => {
      if (data && data.Status) {
        this.setState({ companies: data.Data });
      }

      let unblockedCompanies = [];
      let blockedCompanies = [];
      let pendingConfirmationcompanies = [];

      if (data && data.Status && data.Data.length) {
        data.Data.forEach((company) => {
          if (
            company.status === 1 ||
            company.status === 5 ||
            company.status === 6
          ) {
            unblockedCompanies.push(company);
          } else {
            blockedCompanies.push(company);
          }
        });
      }

      this.setState({
        unblockedCompanies,
        blockedCompanies,
        pendingConfirmationcompanies
      });
    });
  };

  blockCompany = (companyId, status) => {
    let requestData = { currentUserId: this.state.currentUser.id };
    if (status === 4) {
      requestData.unblock = true;
    }

    companyService
      .blockCompany(companyId, requestData)
      .then((data) => {
        if (data && data.Status) {
          this.getAllCompanies();
        }
      })
      .catch((error) => {});
  };

  permanentlyDeleteCompany = (companyId) => {
    companyService.permanentlyDeleteCompany(companyId).then((data) => {
      if (data && data.Status) {
        swal(data.Data.Message, "", "success");
        this.getAllCompanies();
      }
    });
  };

  changeActiveStatusOfCompany = (companyId, status) => {
    let requestData = {
      companyId,
      status
    };
    companyService.changeCompanyActiveStatus(requestData).then((data) => {
      console.log(data);
      swal(data.Data.Message, "", "success");
      this.getAllCompanies();
    });
  };

  actionTemplateforUnblockedCompanies = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer "
          style={{ color: "red" }}
          onClick={() => {
            this.blockCompany(rowData._id, 1);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </span>
      </div>
    );
  };

  actionTemplateforBlockedCompanies = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer "
          style={{ color: "green" }}
          onClick={() => {
            this.blockCompany(rowData._id, 4);
          }}
        >
          <FontAwesomeIcon icon={faUserCheck} />
        </span>
      </div>
    );
  };

  actionTemplateforPermanentlyDelete = (rowData) => {
    return (
      <div className="action-container">
        <span
          className="cursor-pointer "
          style={{ color: "red" }}
          onClick={() => {
            swal({
              title: "Are you sure?",
              text: "You want to delete this company.",
              icon: "warning",
              buttons: true,
              dangerMode: true
            }).then((willDelete) => {
              if (willDelete) {
                this.permanentlyDeleteCompany(rowData._id);
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
      </div>
    );
  };

  actionTemplateforEditCompanyDetails = (rowData) => {
    return (
      <div className="action-container">
        <span className="cursor-pointer ">
          <Link to={`/change-user-details/${rowData._id}`}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </Link>
        </span>
      </div>
    );
  };

  dateTemplate = (rowData) => {
    return moment(rowData.createdAt).format("MM/DD/YYYY hh:mm a");
  };

  statusTemplate = (rowData) => {
    return (
      <div className="action-container">
        <span className="cursor-pointer ">
          {rowData.status == 5 ? (
            <>
              <Tag className="mr-1" severity="warning" value="Pending"></Tag>
            </>
          ) : null}

          {rowData.status == 6 ? (
            <>
              <Tag className="mr-1" severity="danger" value="Rejected"></Tag>
            </>
          ) : null}
          {rowData.status == 1 ? (
            <>
              <Tag className="mr-2" severity="success" value="Active"></Tag>
            </>
          ) : null}
        </span>
      </div>
    );
  };

  changeActiveStatusForCompany = (rowData) => {
    return (
      <div className="action-container">
        {rowData.status == 5 ? (
          <div className="action-container">
            <span
              className="cursor-pointer text-success"
              data-toggle="tooltip"
              title="Approve"
              onClick={(e) => {
                this.changeActiveStatusOfCompany(rowData._id, 1);
              }}
            >
              <FontAwesomeIcon icon={faThumbsUp} />
            </span>
            <span
              className="cursor-pointer text-danger"
              data-toggle="tooltip"
              title="Disapprove"
              onClick={() => {
                this.changeActiveStatusOfCompany(rowData._id, 6);
              }}
            >
              <FontAwesomeIcon icon={faThumbsDown} />
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  appendQueryStringOnTextChangeinURL = (registeredName, deletedName) => {
    if (
      this.props.history.location.pathname &&
      this.props.history.location.search === ""
    ) {
      this.props.history.push({
        pathname: "/company-registration-list",
        search: `?req=${registeredName}&del=${deletedName}`
      });
    } else {
      this.props.history.replace({
        pathname: "/company-registration-list",
        search: `?req=${registeredName}&del=${deletedName}`
      });
    }
  };

  render() {
    const items = [{ label: "Company Registration List" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };
    const header = (
      <div className="table-header">
        Company Registration List
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Search"
          />
        </span>
      </div>
    );
    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card compyRegistrList">
                <div className="mb-3 table-header">
                  <h2>Company Registration List</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) => {
                        this.setState(
                          {
                            globalFilterforUnblockedCompanies: e.target.value,
                            companyNameLastSearch: e.target.value
                          },
                          () => {
                            this.appendQueryStringOnTextChangeinURL(
                              this.state.companyNameLastSearch,
                              this.state.companyNameLastSearchDeleted
                            );
                          }
                        );
                      }}
                      placeholder="Search"
                      value={this.state.companyNameLastSearch}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={this.state.unblockedCompanies}
                    className="p-datatable-striped p-datatable-responsive-demo"
                    header="Responsive"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={this.state.globalFilterforUnblockedCompanies}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    <Column field="email" header="Email" sortable>
                      {" "}
                    </Column>
                    <Column
                      field="createdAt"
                      header="Created"
                      body={this.dateTemplate}
                      sortable
                    ></Column>
                    <Column
                      field="status"
                      header="Status"
                      body={this.statusTemplate}
                      sortable
                    ></Column>
                    <Column
                      className="text-center"
                      header="Change Status"
                      sortable
                      body={this.changeActiveStatusForCompany}
                    ></Column>
                    <Column
                      body={this.actionTemplateforEditCompanyDetails}
                      className="text-center"
                      header="Action"
                    ></Column>
                    <Column
                      className="text-center"
                      header="Delete"
                      body={this.actionTemplateforUnblockedCompanies}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>

          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card compyRegistrList">
                <div className="mb-3 table-header">
                  <h2>Company Registration List || Deleted</h2>
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      type="search"
                      onInput={(e) =>
                        this.setState(
                          {
                            globalFilterforblockedCompanies: e.target.value,
                            companyNameLastSearchDeleted: e.target.value
                          },
                          () => {
                            this.appendQueryStringOnTextChangeinURL(
                              this.state.companyNameLastSearch,
                              this.state.companyNameLastSearchDeleted
                            );
                          }
                        )
                      }
                      placeholder="Search"
                      value={this.state.companyNameLastSearchDeleted}
                    />
                  </span>
                </div>
                <div className="custom-table-responsive ">
                  <DataTable
                    value={this.state.blockedCompanies}
                    className="p-datatable-striped p-datatable-responsive-demo"
                    header="Responsive"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    rows={10}
                    globalFilter={this.state.globalFilterforblockedCompanies}
                    rowsPerPageOptions={[10, 20, 50]}
                  >
                    <Column
                      field="demographics.companyTitle"
                      header="Company Title"
                      sortable
                    ></Column>
                    <Column field="email" header="Email" sortable>
                      {" "}
                    </Column>
                    <Column
                      field="createdAt"
                      header="Created"
                      body={this.dateTemplate}
                      sortable
                    ></Column>
                    <Column
                      body={this.actionTemplateforEditCompanyDetails}
                      className="text-center"
                      header="Action"
                    ></Column>
                    <Column
                      className="text-center"
                      header="Activate"
                      body={this.actionTemplateforBlockedCompanies}
                    ></Column>
                    <Column
                      className="text-center"
                      header="Permanently Delete"
                      body={this.actionTemplateforPermanentlyDelete}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
