import React, { Component } from "react";
import { Link } from "react-router-dom";
import { InputMask } from "primereact/inputmask";
import { BreadCrumb } from "primereact/breadcrumb";
import { authenticationService } from "../../_services";
import { departmentService } from "../../_services/superadmin/department.service";
import { userService } from "../../_services/superadmin/user.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Preloader from "../../components/Preloader";
import Select from "react-select";
import PasswordMask from "react-password-mask";
import config from "../../config";
import axios from "axios";
import mime from "mime-types";
import * as Yup from "yup";
import { validateEmail } from "../../_helpers/_helperFunctions";
export default class AddEditUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: authenticationService.currentUserValue,
      user: {},
      department: [],
      selectedDepartment: [],
      profilepicture: "",
      url: "",
    };
  }

  componentDidMount() {
    this.getAllDepartments();

    this.props.match.params.id &&
      userService.getUserById(this.props.match.params.id).then((data) => {
        if (data.Data.profileImageUrl) {
          this.setState({
            user: data.Data,
            loading: false,
            profilepicture: data.Data.profileImageUrl,
            url: data.Data.profileImageUrl,
          });
        } else {
          this.setState({ user: data.Data, loading: false });
        }
        setTimeout(() => {
          this.state.department.forEach((item) => {
            this.state.user.department.forEach((depres) => {
              if (item._id === depres) {
                let res = [
                  ...this.state.selectedDepartment,
                  {
                    label: item.name,
                    value: item._id,
                  },
                ];
                this.setState({
                  selectedDepartment: res,
                });
              }
            });
          });
        }, 500);
      });
  }

  getAllDepartments() {
    departmentService
      .getAll()
      .then((data) => this.setState({ department: data.Data }));
  }

  onChangeFileHandler = (event) => {
    this.setState({
      profilepicture: "",
      url: "",
      imageLoading: true,
      showImgError: false,
    });
    if (event.target.files[0]) {
      document.getElementById("img_url").src = (window.URL
        ? URL
        : window.webkitURL
      ).createObjectURL(event.target.files[0]);
      let file = event.target.files[0];
      // Split the filename to get the name and type
      let fileName = "user-profile/" + event.target.files[0].name;
      let fileType = mime.lookup(event.target.files[0].name);
      console.log(fileName);
      console.log(fileType);
      console.log("Preparing the upload");

      axios
        .post(
          `${config.apiUrl}/api/v1/users/profile-image`,
          {
            fileName: fileName,
            fileType: fileType,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`,
              isenc: localStorage.getItem("isenc")
                ? parseInt(localStorage.getItem("isenc"))
                : 0,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          var returnData = response.data.Data;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
          this.setState({ url: url });
          console.log("Recieved a signed request " + signedRequest);

          // Put the fileType in the headers for the upload
          var options = {
            headers: {
              "Content-Type": fileType,
            },
          };
          axios
            .put(signedRequest, file, options)
            .then((result) => {
              console.log("Response from s3", result);
              this.setState({ success: true, imageLoading: false });
            })
            .catch((error) => {
              alert("ERROR " + JSON.stringify(error));
            });
        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });
    } else {
      document.getElementById("img_url").src = "";
      this.setState({
        url: "",
        imageLoading: false,
        showImgError: true,
      });
      // document.getElementById("validimage").innerHTML = "Choose Your Image"
    }
  };

  render() {
    const items = [{ label: "Add-Edit Department" }];
    const home = { icon: "pi pi-home", url: "/aboutus" };

    return (
      <>
        <BreadCrumb model={items} home={home} className="mb-3" />
        <div className="p-grid">
          <div className="p-col-12">
            <div className="datatable-filter">
              <div className="card">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    userId: this.state.user._id || null,
                    firstName: this.state.user.firstName || "",
                    lastName: this.state.user.lastName || "",
                    password: "",
                    email: this.state.user.email || "",
                    dob: this.state.user.dob || "",
                    phoneNumber: this.state.user.phoneNumber || "",
                    alternativeNumber: this.state.user.alternativeNumber || "",
                    homeAddress: this.state.user.homeAddress || "",
                    role: this.state.user.role || "",
                    staffType: this.state.user.staffType || "",
                    accessType: this.state.user.accessType || "",
                    currentUserId: this.state.currentUser.id,
                    department: this.state.user.department || [],
                    isActive: this.state.user.isActive || "",
                    accessModules: this.state.accessModules || [],
                    // url: this.state.user.profileImageUrl || "",
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required("Name is required"),
                    lastName: Yup.string().required("Lastname is required"),
                    password: Yup.string().when({
                      is: (val) => (this.state.user._id ? true : false),
                      then: Yup.string().when({
                        is: (inVal) =>
                          inVal && inVal.length > 0 ? true : false,
                        then: Yup.string()
                          .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])/,
                            "Must have both uppercase and lowercase letters"
                          )
                          .matches(/^(?=.*[0-9])/, "Must consist of a number")
                          .matches(
                            /^(?=.{8,})/,
                            "Must be atleast 8 character long"
                          )
                          .required("Password is required"),
                      }),
                      otherwise: Yup.string()
                        .matches(
                          /^(?=.*[a-z])(?=.*[A-Z])/,
                          "Must have both uppercase and lowercase letters"
                        )
                        .matches(/^(?=.*[0-9])/, "Must consist of a number")
                        .matches(
                          /^(?=.{8,})/,
                          "Must be atleast 8 character long"
                        )
                        .required("Password is required"),
                    }),
                    //   .required('Password is required'),
                    email: Yup.string().required("Email is required"),
                    role: Yup.string().required("Role is required"),
                    dob: Yup.string().required("DOB is Required"),
                    phoneNumber: Yup.string()
                      .required("Phone Number is Required")
                      .min(
                        14,
                        "Phone Number should contain minimum of 10 digits"
                      )
                      .max(
                        14,
                        "Phone Number should contain maximum of 10 digits"
                      ),
                    alternativeNumber: Yup.string()
                      // .required('Alternative Number is Required')
                      .min(
                        14,
                        "Alternative Number should contain minimum of 10 digits"
                      )
                      .max(
                        14,
                        "Alternative Number should contain maximum of 10 digits"
                      ),
                    // homeAddress: Yup.string().required('Home Address is Required'),
                    accessType: Yup.string().required(
                      "Access type is required"
                    ),
                    department: Yup.string().required("Department is required"),
                    // subDepartment: Yup.string().required('Sub Department is required'),
                    // url: Yup.string().required('Profile Picture is required')
                  })}
                  onSubmit={(formData, { setStatus, setSubmitting }) => {
                    if (!this.state.showImgError) {
                      console.log("-----------------------------------");
                      //setStatus();
                      formData.profileImageUrl = this.state.url;
                      formData.accessModules = formData.accessModules.map(
                        (elem) => elem.value
                      );
                      userService
                        .create(formData)
                        .then((user) => {
                          const { from } = this.props.location.state || {
                            from: { pathname: "/super-admin/users" },
                          };
                          this.props.history.push(from);
                        })
                        .catch((error) => {
                          setSubmitting(false);
                          setStatus(error);
                        });
                    }
                  }}
                  render={({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    values,
                    handleChange,
                  }) => (
                    <Form>
                      <div className="card-body">
                        <div className="row form-material">
                          <div className="col-md-12">
                            <div className="form-group required">
                              <label className="control-label">
                                First Name
                              </label>
                              <Field
                                name="firstName"
                                type="text"
                                placeholder="Name"
                                className={
                                  "form-control" +
                                  (errors.firstName && touched.firstName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">
                                {" "}
                                Last Name
                              </label>
                              <Field
                                name="lastName"
                                type="text"
                                placeholder="Last Name"
                                className={
                                  "form-control" +
                                  (errors.lastName && touched.lastName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Email</label>
                              <Field
                                name="email"
                                type="text"
                                placeholder="Email"
                                validate={validateEmail}
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div
                              className={
                                !this.state.user._id
                                  ? "form-group required"
                                  : "form-group"
                              }
                            >
                              <label className="control-label">Password</label>
                              <Field
                                name="password"
                                placeholder="Password"
                                autoComplete="off"
                                render={({ field }) => (
                                  <PasswordMask
                                    // inputStyles={{ border: "none" }}
                                    buttonStyles={{
                                      top: "20px",
                                      backgroundColor: "transparent",
                                    }}
                                    //useVendorStyles={false}
                                    showButtonContent={
                                      <span>
                                        <i className="pi pi-eye"></i>
                                      </span>
                                    }
                                    value={values.password}
                                    hideButtonContent={
                                      <i
                                        className="pi pi-eye"
                                        style={{
                                          backgroundColor: "transparent",
                                        }}
                                      ></i>
                                    }
                                    onChange={(password) =>
                                      password === null
                                        ? setFieldValue("password", "")
                                        : setFieldValue("password", password)
                                    }
                                    {...field}
                                    // className={
                                    //   "form-control" +
                                    //   (errors.password && touched.password
                                    //     ? " is-invalid"
                                    //     : "")
                                    // }
                                    inputClassName={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label">Role</label>
                              <Field
                                name="role"
                                as="select"
                                className={
                                  "form-control" +
                                  (errors.role && touched.role
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Select Role"
                              >
                                <option value="">Select Role</option>
                                <option value="1">Super Admin</option>
                                <option value="2">Admin</option>
                              </Field>
                              <ErrorMessage
                                name="role"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required"></div>
                            <div className="form-group required">
                              <label className="control-label d-inline-block w-100">
                                Department
                              </label>
                              <Field
                                name="department"
                                type="text"
                                autoComplete="off"
                                className={
                                  "form-control" +
                                  (errors.department && touched.department
                                    ? " is-invalid"
                                    : "")
                                }
                                render={({ field }) => (
                                  <Select
                                    isMulti
                                    isSearchable
                                    onChange={(e) => {
                                      let departmentIdList = [];
                                      if (e) {
                                        e.forEach((item) => {
                                          departmentIdList.push(item.value);
                                        });
                                      }
                                      this.setState({
                                        selectedDepartment: e,
                                      });
                                      setFieldValue(
                                        "department",
                                        departmentIdList
                                      );
                                    }}
                                    className={" "}
                                    value={this.state.selectedDepartment}
                                    options={this.state.department?.map(
                                      (department, index) => ({
                                        label: department.name,
                                        value: department._id,
                                      })
                                    )}
                                    placeholder="Select Department"
                                  />
                                )}
                              />
                              <div>
                                {touched.department && errors.department && (
                                  <div
                                    style={{
                                      width: "100%",
                                      marginTop: ".25rem",
                                      fontSize: "80%",
                                      color: "#dc3545",
                                    }}
                                  >
                                    {errors.department}
                                  </div>
                                )}
                              </div>
                              <ErrorMessage
                                name="department"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label">
                                Access Type
                              </label>
                              <Field
                                name="accessType"
                                as="select"
                                className={
                                  "form-control" +
                                  (errors.accessType && touched.accessType
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Select Access type"
                              >
                                <option value="">Select Access type</option>
                                <option value="1">Read</option>
                                <option value="2">ReadWrite</option>
                                <option value="3">Admin</option>
                              </Field>
                              <ErrorMessage
                                name="accessType"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="form-group required">
                              <label className="control-label"> DOB</label>
                              {/* <Field name="dob" type="text" placeholder="DOB"
                                                         className={'form-control' + (errors.dob && touched.dob ? ' is-invalid' : '')} 
                                                         
                                                         /> */}
                              <InputMask
                                mask="99/99/9999"
                                value={values.dob}
                                className={
                                  "form-control" +
                                  (errors.dob && touched.dob
                                    ? " is-invalid"
                                    : "")
                                }
                                slotChar="mm/dd/yyyy"
                                onChange={(e) => setFieldValue("dob", e.value)}
                              ></InputMask>

                              <ErrorMessage
                                name="dob"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <label className="control-label">
                                {" "}
                                Phone Number
                              </label>

                              <InputMask
                                mask="(999) 999-9999"
                                value={values.phoneNumber}
                                className={
                                  "form-control" +
                                  (errors.phoneNumber && touched.phoneNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={(e) =>
                                  setFieldValue("phoneNumber", e.value)
                                }
                              ></InputMask>
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group">
                              <label className="control-label">
                                {" "}
                                Alternative Number
                              </label>

                              <InputMask
                                mask="(999) 999-9999"
                                value={values.alternativeNumber}
                                className={
                                  "form-control" +
                                  (errors.alternativeNumber &&
                                  touched.alternativeNumber
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={(e) =>
                                  setFieldValue("alternativeNumber", e.value)
                                }
                              ></InputMask>
                              <ErrorMessage
                                name="alternativeNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group">
                              <label className="control-label"> Address</label>
                              <Field
                                name="homeAddress"
                                type="text"
                                placeholder="Address"
                                className={
                                  "form-control" +
                                  (errors.homeAddress && touched.homeAddress
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="homeAddress"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="form-group required">
                              <div>
                                <label className="control-label">
                                  {" "}
                                  Profile Picture
                                </label>
                              </div>
                              {/* <Field
                                                                    name="url"
                                                                    className={'form-control' + (errors.url && touched.url ? ' is-invalid' : '')}
                                                                    render={({ field }) => ( */}
                              <div
                                className="d-flex"
                                style={{ marginTop: "5px" }}
                              >
                                <div
                                  className="d-flex"
                                  style={{ flexDirection: "column" }}
                                >
                                  <input
                                    name="url"
                                    // className={(errors.url && touched.url ? ' is-invalid' : '')}
                                    type="file"
                                    onChange={this.onChangeFileHandler}
                                    onClick={this.profileImageEdit}
                                  />
                                  {this.state.showImgError && (
                                    <span
                                      style={{
                                        color: "#dc3545",
                                        marginTop: ".25rem",
                                        fontSize: "80%",
                                      }}
                                    >
                                      Profile Picture is Required
                                    </span>
                                  )}
                                </div>
                                {this.state.imageLoading && (
                                  <img
                                    style={{ height: "27px" }}
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                  />
                                )}
                                {this.state.profilepicture ? (
                                  <img
                                    src={this.state.profilepicture}
                                    id="img_url"
                                    alt="your image"
                                  ></img>
                                ) : (
                                  <img
                                    src=""
                                    id="img_url"
                                    alt="your image"
                                  ></img>
                                )}
                              </div>
                              {/* <ErrorMessage name="url" component="div" className="invalid-feedback" /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer accordionfooter d-flex">
                        <div style={{ marginRight: "5px" }}>
                          <Link
                            to="/super-admin/users"
                            className="btn btn-secondary"
                            style={{
                              backgroundColor: "grey",
                              color: "white",
                            }}
                          >
                            {/* <i className="mdi mdi-chevron-left"></i> */}
                            <span className="hide-menu">Cancel</span>
                          </Link>
                        </div>
                        <button
                          onClick={this.checkimg}
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary"
                        >
                          {" "}
                          {!this.state.user._id
                            ? "Save User"
                            : "Update User"}{" "}
                        </button>
                        {isSubmitting && (
                          <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        )}
                      </div>
                      {status && (
                        <div className={"alert alert-danger"}>{status}</div>
                      )}
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
