import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//import "./App.css";
import LoginPage from "./pages/LoginPage";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AboutUs from "./pages/Admin/AboutUs";
import ChatPage from "./pages/Admin/ChatPage";
import Departments from "./pages/SuperAdmin/Departments";
import Users from "./pages/SuperAdmin/Users";
import AddEditDepartment from "./pages/SuperAdmin/AddEditDepartment";
import AddEditUser from "./pages/SuperAdmin/AddEditUser";
import CompanyRegistrationList from "./pages/Admin/CompanyRegistrationList";
import APMACompanyList from "./pages/Admin/APMACompanyList";
import CompanyProfileRequest from "./pages/Admin/CompanyProfileRequests";
import CompanyHighlightsRequest from "./pages/Admin/CompanyHighlightsRequests";
import CompanyDetails from "./pages/Admin/CompanyDetails";
import CompanyBadgeAssignment from "./pages/Admin/CompanyBadgeAssigment";
import CompanyFeaturedList from "./pages/Admin/CompanyFeaturedList";
import CompanyPublishList from "./pages/Admin/CompanyPublishList";
import ChangePassword from "./pages/Admin/ChangePassword";
import Invite from "./pages/Admin/Invites";
import Sectors from "./pages/Admin/Sectors";
import ServicesKeyword from "./pages/Admin/ServicesKeyword";
import AddEditServiceKeyword from "./pages/Admin/AddEditServiceKeyword";
import AddEditSectors from "./pages/Admin/AddEditSectors";
import AddCompanyData from "./pages/Admin/AddCompanyData";
import Dashboard from "./pages/Admin/Dashboard";
import EditUserDetails from "./pages/Admin/EditUserDetails";
import { history, Role } from "./_helpers";
import { authenticationService } from "./_services";
import { PrivateRoute } from "./components/PrivateRoute";
import DashboardLayoutRoute from "./layout/components/DashboardLayoutRoute";
import ApmaLayoutRoute from "./layout/components/ApmaLayoutRoute";
import PublishCompanyEditDetails from "./pages/Admin/PublishCompanyEditDetails";
import AgreedCompanies from "./pages/Admin/AgreedCompanies";
import ExportCompanyData from "./pages/Admin/ExportCompanyData";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
//import 'primereact/resources/themes/nova-light/theme.css';
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/layout.scss";
import "./stylesheets/spinners.css";
import "./App.css";

import ApmaProfile from "./pages/Admin/ApmaProfile";

//import jQuery from 'jquery';

//window.jQuery = jQuery;
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isAdmin: false
    };
  }

  componentWillMount() {
    if (!localStorage.getItem("isenc")) {
      localStorage.setItem("isenc", "0");
    }
    document.addEventListener("keydown", keyDownTextField, false);

    function keyDownTextField(e) {
      var keyCode = e.keyCode;
      if (e.ctrlKey && e.altKey && e.key === "e") {
        let encMode = localStorage.getItem("isenc")
          ? localStorage.getItem("isenc")
          : "0";
        if (encMode !== "1") {
          localStorage.setItem("isenc", "1");
          alert("Enc Mode On");
        } else {
          localStorage.setItem("isenc", "0");
          alert("Enc Mode Off");
        }
      }
    }
    if (!localStorage.getItem("pemPublic")) {
      fetch("./security/public_client.pem", { mode: "no-cors" })
        .then((response) => response.text())
        .then((data) => {
          console.log("file content", data);
          fetch("./security/private_client.pem", { mode: "no-cors" })
            .then((response1) => response1.text())
            .then((data1) => {
              localStorage.setItem("pemPrivate", data1);
              localStorage.setItem("pemPublic", data);
            });
        })
        .catch((error) => console.error(error));
    }
  }

  render() {
    return (
      <Router history={history} onUpdate={(a) => console.log(a)}>
        {/* Common Routes */}
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/login" component={LoginPage} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset/:token" component={ResetPassword} />
        {/* SuperAdmin's Routes*/}
        <DashboardLayoutRoute
          exact
          path="/super-admin"
          component={AboutUs}
          roles={{ role: Role.SuperAdmin }}
        />
        <DashboardLayoutRoute
          path="/super-admin/departments"
          component={Departments}
          roles={{ role: Role.SuperAdmin }}
        />
        <DashboardLayoutRoute
          path="/super-admin/department/add"
          component={AddEditDepartment}
          roles={{ role: Role.SuperAdmin }}
        />
        <DashboardLayoutRoute
          path="/super-admin/department/edit/:id"
          component={AddEditDepartment}
          roles={{ role: Role.SuperAdmin }}
        />
        <DashboardLayoutRoute
          path="/super-admin/users"
          component={Users}
          roles={{ role: Role.SuperAdmin }}
        />
        <DashboardLayoutRoute
          path="/super-admin/user/add"
          component={AddEditUser}
          roles={{ role: Role.SuperAdmin }}
        />
        <DashboardLayoutRoute
          path="/super-admin/user/edit/:id"
          component={AddEditUser}
          roles={{ role: Role.SuperAdmin }}
        />
        <DashboardLayoutRoute
          path="/super-admin/edit-user-details"
          component={PublishCompanyEditDetails}
          roles={{ role: Role.SuperAdmin }}
        />

        <DashboardLayoutRoute
          path="/super-admin/change-user-details/:id"
          roles={{ role: Role.SuperAdmin }}
          component={EditUserDetails}
        />
        {/* Admin's Routes */}
        <DashboardLayoutRoute
          exact
          path="/admin"
          component={AboutUs}
          roles={{ role: Role.Admin }}
        />
        <DashboardLayoutRoute path="/aboutus" component={AboutUs} />
        <DashboardLayoutRoute
          path="/company-registration-list"
          component={CompanyRegistrationList}
          roles={{ role: Role.Admin }}
        />
        <DashboardLayoutRoute
          path="/company-profile-requests"
          component={CompanyProfileRequest}
          roles={{ role: Role.Admin }}
        />
        <DashboardLayoutRoute
          path="/company-highlights-requests"
          component={CompanyHighlightsRequest}
          roles={{ role: Role.Admin }}
        />
        <DashboardLayoutRoute
          path="/inbox"
          component={ChatPage}
          roles={{ role: Role.Admin }}
        />
        <DashboardLayoutRoute
          path="/company-details/:id"
          component={CompanyDetails}
          roles={{ role: Role.Admin }}
        />
        <DashboardLayoutRoute
          path="/assign-badge"
          roles={{ role: Role.Admin }}
          component={CompanyBadgeAssignment}
        />
        <DashboardLayoutRoute
          path="/featured-companies"
          roles={{ role: Role.Admin }}
          component={CompanyFeaturedList}
        />
        <DashboardLayoutRoute
          path="/sectors/edit/:sectorId"
          exact
          roles={{ role: Role.Admin }}
          component={AddEditSectors}
        />
        <DashboardLayoutRoute
          path="/sectors/add"
          exact
          roles={{ role: Role.Admin }}
          component={AddEditSectors}
        />
        <DashboardLayoutRoute
          path="/sectors"
          exact
          roles={{ role: Role.Admin }}
          component={Sectors}
        />
        <DashboardLayoutRoute
          path="/servicesKeyword"
          exact
          roles={{ role: Role.Admin }}
          component={ServicesKeyword}
        />
        <DashboardLayoutRoute
          path="/servicesKeyword/add"
          exact
          roles={{ role: Role.Admin }}
          component={AddEditServiceKeyword}
        />
        <DashboardLayoutRoute
          path="/servicesKeyword/edit/:serviceKeywordId"
          exact
          roles={{ role: Role.Admin }}
          component={AddEditServiceKeyword}
        />
        <DashboardLayoutRoute
          path="/invite"
          roles={{ role: Role.Admin }}
          component={Invite}
        />
        <DashboardLayoutRoute
          path="/invite-company-data"
          roles={{ role: Role.Admin }}
          component={AddCompanyData}
        />

        <DashboardLayoutRoute
          path="/analytics-dashboard"
          roles={{ role: Role.Admin }}
          component={Dashboard}
        />
        <DashboardLayoutRoute
          path="/publish-companies"
          component={CompanyPublishList}
        />
        <DashboardLayoutRoute
          path="/verified-by-company"
          roles={{ role: Role.Admin }}
          component={AgreedCompanies}
        />

        {/* <DashboardLayoutRoute
          path="/change-password"
          roles={{ role: Role.Admin }}
          component={ChangePassword}
        /> */}
        <ApmaLayoutRoute
          path="/apma-company-list"
          roles={{ role: Role.Admin }}
          component={APMACompanyList}
        />
        <ApmaLayoutRoute
          path="/apma-profile/:id"
          roles={{ role: Role.Admin }}
          component={ApmaProfile}
        />
        <ApmaLayoutRoute
          path="/change-password"
          roles={{ role: Role.Admin }}
          component={ChangePassword}
        />
        <DashboardLayoutRoute
          path="/change-user-details/:id"
          roles={{ role: Role.Admin }}
          component={EditUserDetails}
        />
        <DashboardLayoutRoute
          path="/edit-user-details"
          roles={{ role: Role.Admin }}
          component={PublishCompanyEditDetails}
        />
        <DashboardLayoutRoute
          path="/export-company-data"
          roles={{ role: Role.Admin }}
          component={ExportCompanyData}
        />
      </Router>
    );
  }
  componentDidMount() {
    authenticationService.currentUser.subscribe((x) =>
      this.setState({
        currentUser: x,
        isAdmin: x && x.role === Role.Admin
      })
    );

    // let host = window.location.origin;

    // let cssFiles = [

    // ];
    // cssFiles.map((t) => {
    //   let link = document.createElement("link");
    //   link.href = host + t;
    //   link.rel = 'stylesheet';
    //   document.head.appendChild(link);
    //   return true;
    // });
    // let scripts = [
    //   host + '/assets/plugins/jquery/jquery.min.js',
    //   // 'https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.16/jquery.mask.min.js',
    //   'https://cdnjs.cloudflare.com/ajax/libs/jquery.inputmask/5.0.4-beta.33/bindings/inputmask.binding.min.js',
    //   host + '/assets/plugins/popper/popper.min.js',
    //   host + '/assets/plugins/bootstrap/js/bootstrap.min.js',
    //   host + '/assets/js/jquery.slimscroll.js',
    //   host + '/assets/js/waves.js',
    //   host + '/assets/js/sidebarmenu.js',
    //   host + '/assets/plugins/sticky-kit-master/dist/sticky-kit.min.js',
    //   host + '/assets/plugins/sparkline/jquery.sparkline.min.js',
    //   host + '/assets/plugins/styleswitcher/jQuery.style.switcher.js',
    //   host + '/assets/plugins/bootstrap-datepicker/bootstrap-datepicker.min.js',
    //   host + '/assets/plugins/select2/dist/js/select2.full.min.js',
    //   host + '/assets/js/custom.min.js',
    // ];

    // scripts.map((t) => {
    //   let script = document.createElement("script");
    //   script.src = t;
    //   script.async = false;
    //   document.body.appendChild(script);
    //   return true;
    // });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
