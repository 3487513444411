module.exports = {
  apiUrl:
    process.env.NODE_ENV === "development"
      ? "https://weedc-admin-dev.yqgpros.com"
      : "https://weedc-admin-dev.yqgpros.com",
  getAllCountries:
    "https://restcountries.eu/rest/v2/all?fields=name;languages;callingCodes",
  weedcLink: "https://sourcewindsoressex.com/register"
};

